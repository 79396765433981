/*!
  SPARTA v0.1
  This file is for theme configuration. These variables are used in global and component CSS files.

  You can:
    1) Set new values for Bootstrap variables - https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss
    2) Set new values for cxbase variables - cxbase/_variables.scss
    3) Set new values for component variables - app/__/_.scss
  You cannot:
    1) Add new variables
*/
@import '../../../../../node_modules/@spartacus/styles/scss/cxbase/functions';
@import '../../../../../node_modules/@spartacus/styles/scss/cxbase/mixins';

//fonts (see _fonts.scss to import)
$font-family-red-hat: 'Red Hat Display', sans-serif;

// Basic colors
$valio-white: #FFFFFF !default;
$valio-black: #000000 !default;
$valio-transparent: transparent !default;

// VALO Primary colors
$valio-purple: #3F1B8C !default; // CTAs
$valio-dark: #383838 !default; // Copy
$valio-gray: #9695A4 !default; // Secondary copy

// VALO Pastels
$valio-nude: #FFF0EB !default; // Background elements
$valio-peach: #FFEDEB !default; // Background elements
$valio-light-peach: #FFFAF9 !default; // Background elements
$valio-mint: #EFFBFB !default; // Background elements
$valio-blueberry: #DADDFF !default; // Background elements
$valio-light-purple: #F0E6FF !default; // Background elements
$valio-medium-purple: #5023B0 !default; // Background elements
$valio-dark-purple: #2A115D !default; // Background elements
$valio-pink: #FAF3FF !default; // Background elements
$valio-cloud: #FEFAFF !default; // Background elements

// VALO Support colors
$valio-status-done: #69AE23 !default; // Status
$valio-status-open: #E58F0E !default; // Status
$valio-status-closed: $valio-dark; // Status
$valio-alarm-discount: #FF3B55 !default; // error
$valio-alarm-warning: #FDA013 !default; // warning
$valio-alarm-warning-dark: #E68A00 !default; // warning dark
$valio-alarm-info: $valio-alarm-warning !default; // info
$valio-rosa: #E9D2D2 !default; // Supportive elements: lines on top of peach etc.
$valio-iris: #D2D2E9 !default; // Supportive elements: lines on top of purple etc.

// Other colors from Figma
$valio-line: #F0F0F0 !default;
$valio-calendar-activity-entry-border: #F1E7E7 !default;
$valio-product-image-background: #F6F6F6 !default;
$valio-main-navigation-background: #FAFAFA !default;
$valio-alice-blue: #EBF3FF !default;

$theme-colors: (
  // override standard theme colors
  'primary': $valio-purple,
  'secondary': $valio-peach,
  'red': $valio-alarm-discount,
  'danger': $valio-alarm-discount,
  'white': $valio-white,
  'black': $valio-black,
  'background': $valio-pink,
  'text': $valio-dark,
  'visual-focus' : $valio-transparent,
  // VALO theme colors
  'purple': $valio-purple,
  'dark': $valio-dark,
  'gray': $valio-gray,
  'nude': $valio-nude,
  'peach': $valio-peach,
  'light-peach': $valio-light-peach,
  'mint': $valio-mint,
  'blueberry': $valio-blueberry,
  'light-purple': $valio-light-purple,
  'medium-purple': $valio-medium-purple,
  'dark-purple': $valio-dark-purple,
  'pink': $valio-pink,
  'cloud': $valio-cloud,
  'status-done': $valio-status-done,
  'status-open': $valio-status-open,
  'status-closed': $valio-status-closed,
  'alarm-discount': $valio-alarm-discount,
  'alarm-warning': $valio-alarm-warning,
  'alarm-warning-dark': $valio-alarm-warning-dark,
  'alarm-info': $valio-alarm-info,
  'rosa': $valio-rosa,
  'iris': $valio-iris,
  // Other colors from Figma
  'line': $valio-line,
  'calendar-activity-entry-border' : $valio-calendar-activity-entry-border,
  'product-image-background': $valio-product-image-background,
  'main-navigation-background': $valio-main-navigation-background,
  'alice-blue': $valio-alice-blue
);

$valio-font-weight-light: lighter !default;
$valio-font-weight-normal: normal !default;
$valio-font-weight-semi: bolder !default;
$valio-font-weight-bold: bold !default;

$font-weight-light: $valio-font-weight-light;
$font-weight-normal: $valio-font-weight-normal;
$font-weight-semi: $valio-font-weight-semi;
$font-weight-bold: $valio-font-weight-bold;

$font-weight-base: $valio-font-weight-normal;
$btn-font-weight: $valio-font-weight-normal;

//bring in variable defaults
@import '../../../../../node_modules/@spartacus/styles/scss/cxbase/variables';

/*
GLOBAL VARIABLE OVERRIDES
  These can be set with anything above or any variables exposed in Bootsrap or cxbase
*/

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$valio-font-family-base: $font-family-red-hat;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$valio-btn-border-radius: 4px;
$valio-lg-border-radius: 8px;

//Height for all inputs
$valio-input-height-lg: 53px;
$valio-input-content-lg: 51px;
$valio-input-height-sm: 40px;
$valio-input-content-sm: 38px;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$valio-product-grid-breakpoint-xs: 400px; // 2 product cards, no calendar
$valio-product-grid-breakpoint-sm: 592px; // 3 product cards, no calendar
// Bigger page container padding on breakpoint MD 768px
$valio-product-grid-breakpoint-md: 848px; // 4 product cards, no calendar
// Calendar side panel on breakpoint LG 992px
$valio-product-grid-breakpoint-lg: 992px; // 2 product cards, with calendar
$valio-product-grid-breakpoint-xl: 1128px; // 3 product cards, with calendar
$valio-product-grid-breakpoint-xxl: 1320px; // 4 product cards, with calendar
$valio-product-grid-breakpoint-xxxl: 1512px; // 5 product cards, with calendar
$valio-product-grid-breakpoint-xxxxl: 1704px; // 6 product cards, with calendar
$valio-product-grid-breakpoint-xxxxxl: 1896px; // 7 product cards, with calendar

$valio-product-grid-full-breakpoint-xs: 400px; // 2 product cards, no calendar
$valio-product-grid-full-breakpoint-sm: 592px; // 3 product cards, no calendar
// Bigger page container padding on breakpoint MD 768px
$valio-product-grid-full-breakpoint-md: 848px; // 4 product cards, no calendar
$valio-product-grid-full-breakpoint-lg: 1056px; // 5 product cards, no calendar
$valio-product-grid-full-breakpoint-xl: 1248px; // 6 product cards, no calendar
$valio-product-grid-full-breakpoint-xxl: 1440px; // 7 product cards, no calendar
$valio-product-grid-full-breakpoint-xxxl: 1632px; // 8 product cards, no calendar
$valio-product-grid-full-breakpoint-xxxxl: 1824px; // 9 product cards, no calendar


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$valio-page-sm-max-width: 960px; // Pages with background image eg. login, register
$valio-page-md-max-width: 1440px; // Default page width
$valio-page-lg-max-width: 1920px; // Pages with calendar column eg. frontpage


// Container paddings
//
$valio-container-padding: 48px;
$valio-container-padding-md: 24px;
$valio-container-padding-sm: 16px;
$valio-content-margin: -48px;
$valio-content-margin-sm: -16px;
