valio-calendar-component {
  width: 100%;

  .calendar-side-section {
    @include media-breakpoint-up(lg) {
      width: 456px;
    }
  }

  .calendar-side-section {
    @include valio-flex-box();
    @include valio-flex-direction-column();
    height: 100%;

    .calendar-header {
      @include valio-flex-0();

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    valio-calendar-date-component {
      @include valio-flex-box();
      @include valio-flex-direction-column();
      @include valio-flex-1();

      .calendar {
        @include valio-flex-0();

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      .activity-log {
        @include valio-flex-1();

        @include media-breakpoint-up(lg) {
          min-height: 400px;
        }
      }
    }
  }
}

valio-cx-order-template-content {
  .popupCalendar {
    position: absolute;
    bottom: 56px;
    border: 1px solid var(--cx-color-dark);
    height: 355px;
    top: auto;
  }
}

valio-cx-shipping-address-details {
  .shipping-address {
    valio-calendar-date-component {
      margin-bottom: 24px;

      .calendar {
        padding: 0;

        ::-webkit-scrollbar {
          width: 10px;
        }

        .calendar-wrapper {
          border: 1px solid var(--cx-color-dark);
          height: 371px;
        }
      }

      .activity-log-header {
        display: none;
      }
    }
  }
}

valio-calendar-component,
.shipping-address,
.popupCalendar {
  .calendar-header {
    @include valio-flex-box();
    border: none;
    background-color: var(--cx-color-light-peach);
    text-align: center;
    color: var(--cx-color-text);

    .calendar-header-tab {
      @include valio-link-pseudos();
      @include valio-copy-mini-14();
      height: 56px;
      padding: 16px 0;
      background-color: var(--cx-color-white);
      cursor: pointer;

      &.active {
        @include valio-bold-link-mini-14();
        background-color: var(--cx-color-light-peach);
      }

      &.align-left {
        text-align: left;
        margin-left: 16px;
      }
    }
  }

  valio-calendar-date-component {
    display: block;

    .calendar {
      @include valio-scrollbar();
      padding: 24px;

      @include media-breakpoint-down(md) {
        padding: 16px;
      }

      .calendar-wrapper {
        @include valio-border-radius($valio-btn-border-radius);
        overflow-y: scroll;
        overflow-x: hidden;
        height: 350px;
        position: relative;

        .calendar-table {
          width: 100%;
          background-color: var(--cx-color-white);

          .calendar-week-row,
          .calendar-head-row,
          .calendar-month-row {
            float: none;
            clear: both;

            div {
              display: inline-block;
              float: left;
            }
          }

          .calendar-week-row,
          .calendar-head-row {
            text-align: left;

            div {
              padding: 2px;
              width: 12%;
              height: 56px;
              line-height: 56px;
            }

            .endOfMonth {
              display: block;
            }
          }

          .calendar-head-row,
          .calendar-month-row {
            + .calendar-week-row {
              .week {
                @include valio-border-radius($valio-btn-border-radius $valio-btn-border-radius 0 0);
              }
            }
          }

          .calendar-head-row {
            height: 32px;
            border-bottom: 1px solid var(--cx-color-pink);
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            background-color: var(--cx-color-white);
            z-index: 1;

            div {
              @include valio-tiny-12();
              color: var(--cx-color-text);
              height: 32px;
              line-height: 32px;
            }
          }

          .calendar-week-row {
            height: 56px;
            border-bottom: 1px solid var(--cx-color-pink);

            &.last,
            &:last-child {
              .week {
                @include valio-border-radius(0 0 $valio-btn-border-radius $valio-btn-border-radius);
              }
            }
          }

          .calendar-month-row {
            height: 56px;

            .month {
              @include valio-link-bold-small-16();
              color: var(--cx-color-text);
              text-align: left;

              span {
                display: block;
                height: 56px;
                line-height: 56px;
                margin: 0 24px;
              }
            }
          }

          div {
            @include valio-copy-small-16();
            color: var(--cx-color-gray);
            text-align: center;
            padding: 0;
            position: relative;

            &.week {
              @include valio-copy-small-16();
              color: var(--cx-color-text);
              width: 48px;
              background-color: var(--cx-color-white);
              border-right: 1px solid var(--cx-color-pink);
              height: 56px;
              line-height: 56px;
            }

            &.day {
              @include valio-copy-18();
              cursor: pointer;

              span {
                @include valio-border-radius($valio-btn-border-radius);
                display: block;
                width: 38px;
                height: 36px;
                line-height: 36px;
                margin: 8px auto;
              }

              &.has-items {
                &:before {
                  @include valio-border-radius(50%);
                  width: 5px;
                  height: 5px;
                  content: " ";
                  position: absolute;
                  top: 48px;
                  right: 0;
                  left: 0;
                  margin: auto;
                }
              }

              &.has-accepted {
                &:before {
                  background: var(--cx-color-status-done);
                }
              }

              &.has-closed {
                &:before {
                  background: var(--cx-color-black);
                }
              }

              &.has-error {
                &:before {
                  background: var(--cx-color-red);
                }
              }

              &.has-open {
                &:before {
                  background: var(--cx-color-warning);
                }
              }

              &.selectable {
                @include valio-link-bold-18();
                color: var(--cx-color-text);

                span {
                  background: var(--cx-color-secondary);
                }

                &:hover {
                  color: var(--cx-color-white);

                  span {
                    background: var(--cx-color-primary);
                  }
                }
              }

              &.today {
                @include valio-link-bold-18();
                color: var(--cx-color-primary);
              }

              &.active {
                color: var(--cx-color-white);

                span {
                  background: var(--cx-color-primary);
                }

                &:before {
                  background: var(--cx-color-primary);
                }

                &:hover {
                  span {
                    background: var(--cx-color-primary);
                  }
                }
              }

              &.exceptionDate {
                span {
                  background: repeating-linear-gradient(
                      45deg,
                      var(--cx-color-white),
                      var(--cx-color-white) 5px,
                      var(--cx-color-peach) 5px,
                      var(--cx-color-peach) 10px
                  );
                }
              }

              &.non-selectable {
                pointer-events: none;
              }

              &.weekend {
              }
            }
          }

          .year {
            display: inline-block;
            float: right;
            width: auto;
            height: auto;
            margin-right: 10px;
          }

          .current {
            font-weight: bold;
          }
        }
      }

      .calendar-back-today {
        @include valio-link-bold-small-16();
        @include valio-link-pseudos();
        color: var(--cx-color-text);
        text-align: center;
        margin-top: 24px;
        font-size: 14px;
      }
    }

    .activity-log-header {
      background: var(--cx-color-pink);
      padding: 16px 16px 24px;

      @include media-breakpoint-up(md) {
        padding-left: 0;
      }
    }

    .activity-log {
      @include valio-scrollbar();
      position: relative;
      padding: 4px 16px 24px 24px;
      background: var(--cx-color-white);

      @include media-breakpoint-down(md) {
        padding: 16px;
      }

      .activity-entry-wrapper {
        padding-right: 20px;

        @include media-breakpoint-down(md) {
          padding-right: 16px;
        }
        @include media-breakpoint-up(lg) {
          overflow: auto;
          position: absolute;
          top: 4px;
          bottom: 24px;
          left: 24px;
          right: 16px;
        }

        &.mobile-view {
          .activity-entry {

            &.active {
              @include media-breakpoint-down(md) {
                @include valio-flex-box();
              }

              ~ .activity-entry {
                @include media-breakpoint-down(md) {
                  display: none;
                }
              }

              + .activity-entry {
                @include media-breakpoint-down(md) {
                  @include valio-flex-box();
                }

                + .activity-entry {
                  @include media-breakpoint-down(md) {
                    @include valio-flex-box();
                  }

                  + .activity-entry {
                    @include media-breakpoint-down(md) {
                      @include valio-flex-box();
                    }

                    + .activity-entry {
                      @include media-breakpoint-down(md) {
                        @include valio-flex-box();
                      }

                      + .activity-entry {
                        @include media-breakpoint-down(md) {
                          @include valio-flex-box();
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .activity-entry {
          @include valio-flex-box();
          border-top: 1px solid var(--cx-color-rosa);
          padding-top: 24px;
          margin-bottom: 24px;

          @include media-breakpoint-down(md) {
            padding-top: 16px;
            margin-bottom: 16px;
          }

          &:first-child {
            border-top: none;
          }

          &.today {
            valio-date-view {
              .cx-date {
                color: var(--cx-color-primary);
              }
            }
          }

          valio-date-view {
            .cx-date {
              @include valio-H3-24();
              margin-top: 8px;
              text-align: center;

              .supsub {
                display: block;
                padding-left: 0;
                margin-top: -4px;

                .status {
                  display: none;
                }

                .month {
                  @include valio-copy-mini-14();
                  text-transform: lowercase;
                }
              }
            }
          }

          .order-history {
            @include valio-border-radius($valio-btn-border-radius);
            padding: 24px 24px 24px 20px;
            border-left: 4px solid var(--cx-color-transparent);
            box-shadow: 7px 4px 14px rgba(233, 197, 186, 0.2);
            color: var(--cx-color-text);
            background-color: var(--cx-color-white);

            @include media-breakpoint-down(md) {
              padding: 16px;
            }

            &.selectable {
              color: var(--cx-color-text);
              background-color: var(--cx-color-white);
              cursor: pointer;
            }

            &:not(.selectable) {
              pointer-events: none;
            }

            &.has-accepted {
              background-color: var(--cx-color-white);

              &:not(.active) {
                border-left: 4px solid var(--cx-color-status-done);
              }
            }

            &.has-closed {
              background-color: var(--cx-color-white);

              &:not(.active) {
                border-left: 4px solid var(--cx-color-black);
              }
            }

            &.has-error {
              background-color: var(--cx-color-white);

              &:not(.active) {
                border-left: 4px solid var(--cx-color-red);
              }
            }

            &.has-open {
              background-color: var(--cx-color-white);

              &:not(.active) {
                border-left: 4px solid var(--cx-color-warning);
              }
            }

            &.exceptionDate {
              &:not(.active) {
                background: repeating-linear-gradient(
                    45deg,
                    var(--cx-color-white),
                    var(--cx-color-white) 5px,
                    var(--cx-color-secondary) 5px,
                    var(--cx-color-secondary) 10px
                );
              }
            }

            .closing-time {
              @include valio-flex-box();
              @include valio-align-items(center);
              height: $valio-input-height-sm;
            }

            &.active {
              color: var(--cx-color-white);
              background-color: var(--cx-color-primary);

              .sub-title-row {
                .select-date-text {
                  display: none;
                }

                .select-product-text {
                  display: inline;
                }
              }

              .selected-date, .add-products {
                white-space: nowrap;
              }
            }

            > * {
              pointer-events: none;
            }

            + .holiday {
              margin-top: 16px;
            }
          }

          .order-block {
            @include valio-bold-link-mini-14();
            @include valio-border-radius($valio-btn-border-radius);
            margin-top: 16px;
            padding: 16px;
            box-shadow: 7px 4px 14px rgba(233, 197, 186, 0.2);
          }

          .holiday {
            @include valio-border-radius($valio-btn-border-radius);
            padding: 16px;
            box-shadow: 7px 4px 14px rgba(233, 197, 186, 0.2);
            background-color: var(--cx-color-dark);
            color: var(--cx-color-white);
          }

          .title-row {
            @include valio-link-bold-small-16();
          }

          .sub-title-row {
            @include valio-copy-mini-14();

            .select-product-text {
              display: none;
            }
          }

          .horizontal-row {
            hr {
              border-top: 1px solid var(--cx-color-rosa);
            }
          }

          button {
            pointer-events: initial;
          }
        }
      }

      .activity-log-button {
        border-top: 1px solid var(--cx-color-rosa);
        padding: 32px 0 16px;
      }
    }
  }

  .minicart {
    display: block;
    padding: 16px;

    .minicart-wrapper {
      .minicart-header {
        margin-top: 24px;
        padding-bottom: 24px;

        @include media-breakpoint-down(md) {
          margin-top: 16px;
          margin-bottom: 16px;
        }

        .minicart-date {
          @include valio-link-bold-18();
        }

        .minicart-count {
          @include valio-copy-small-16();
          text-align: right;
        }

        .minicart-products {
          @include valio-link-bold-18();
          margin-bottom: 8px;
        }
      }

      valio-cx-cart-item-list {
        input {
          background: var(--cx-color-transparent);
        }

        .collapsed {
          display: none;
        }

        .cx-item-list-row {
          @include valio-copy-mini-14();
          padding-bottom: 16px;

          .cx-item-list-items {
            border-top: 1px solid var(--cx-color-rosa);
            padding-bottom: 16px;
            padding-top: 16px;

            .cx-item {
              margin: 0;
            }

            a {
              color: var(--cx-color-black);
            }

            .cx-name {
              @include valio-link-bold-small-16();
            }

            .cx-code,
            .cart-item-status {
              @include valio-tiny-12();
              padding-top: 8px;
            }

            .cx-total {
              valio-product-icons {
                display: inline-block;
                margin-left: 8px;
              }

              .cx-value {
                padding-top: 8px;
              }
            }

            .cx-quantity {
              .cx-value {
                @include valio-copy-mini-14();
                text-align: right;
              }
            }

            .cx-product-image-container {
              display: block;
              height: 55px;

              .cx-product-image {
                height: 55px;
                width: 55px;

                img {
                  margin: auto;
                }

                &.is-missing {
                  background-color: var(--cx-color-product-image-background);
                  background-size: 48px;
                }

                img,
                &.is-missing {
                  @include valio-border-radius($valio-btn-border-radius);
                  max-width: 55px;
                  min-width: 55px;
                  max-height: 55px;
                  min-height: 55px;
                  position: absolute;
                  left: 0;
                  right: 0;
                  object-fit: contain;
                }
              }
            }

            .cart-item-closing-time {
              @include valio-copy-mini-14();
            }

            .deleted {
              opacity: 0.5;
            }

            .message {
              display: inline-block;
            }
          }
        }
      }

      .minicart-shipping {
        border-top: 1px solid var(--cx-color-rosa);

        .minicart-shipping-header {
          @include valio-link-bold-18();
          margin: 24px 0 8px;
        }
      }

      .paid-products {
        @include valio-copy-mini-14();

        .title {
          padding: 11px 7px;
          background: var(--cx-color-peach);

          .title-label,
          .cx-price {
            @include valio-bold-link-mini-14();
          }
        }

        .info {
          @include valio-copy-mini-14();
          padding: 16px 0;
        }

        .cx-item-list-items {
          &:first-child {
            border-top: none;
          }
        }
      }

      .minicart-to-cart {
        margin: 0;
      }
    }
  }
}

.popupCalendar {
  @include valio-scrollbar();
  @include valio-border-radius($valio-btn-border-radius);
  position: absolute;
  top: 56px;
  left: 0;
  background-color: var(--cx-color-white);
  width: 400px;
  overflow: auto;
  border: 1px solid var(--cx-color-light-peach);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);

  valio-calendar-date-component {
    .calendar {
      padding: 0;
    }

    .activity-log-header {
      display: none;
    }
  }
}

.cart-entry-totals {
  valio-price-summary-component {
    .minicart-separator {
      border-top: none;
    }

    .summary {
      .minicart-total-label {
        text-align: right;

        @include media-breakpoint-down(xs) {
          text-align: left;
        }

        .minicart-total-shipping {
          .total-label {
            text-align: right;

            @include media-breakpoint-down(xs) {
              text-align: left;
            }
          }

          .sum {
            @include valio-link-bold-18();
          }
        }
      }
    }
  }
}

valio-price-summary-component {
  .minicart-separator {
    border-top: 1px solid var(--cx-color-primary);
  }

  .summary {
    .minicart-total-label {
      @include valio-copy-small-16();

      .minicart-total-shipping {
        color: var(--cx-color-primary);
        margin-top: 8px;

        .total-label {
          text-align: left;
        }

        .sum {
          @include valio-link-bold-small-16();
          text-align: right;
        }
      }
    }
  }
}
