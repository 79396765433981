/* Override default paddings and margins for bootstrap columns and rows */
.row {
  margin-left: -8px;
  margin-right: -8px;
}

[class*='col-'] {
  padding-right: 8px;
  padding-left: 8px;
}

.container {
  max-width: 100%;

  @include media-breakpoint-up(md) {
    padding-right: 48px;
    padding-left: 48px;
  }
  @include media-breakpoint-down(sm) {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.gutter-24 {
  > [class*='col-'] {
    @include media-breakpoint-up(lg) {
      padding-right: 12px;
      padding-left: 12px;
    }
    @include media-breakpoint-down(md) {
      padding-right: 8px;
      padding-left: 8px;
    }

    > .row {
      @include media-breakpoint-up(lg) {
        margin-left: -12px;
        margin-right: -12px;
      }
      @include media-breakpoint-down(md) {
        margin-left: -8px;
        margin-right: -8px;
      }
    }
  }
}

.margin-top-base {
  margin-top: 16px;
}

.margin-top-md {
  margin-top: 24px;
}

.margin-top-lg {
  margin-top: 48px;
}

.margin-bottom-sm {
  margin-bottom: 8px;
}

.margin-bottom-base {
  margin-bottom: 16px;
}

.margin-bottom-md {
  margin-bottom: 24px;
}

.margin-bottom-lg {
  margin-bottom: 48px;
}

.margin-bottom-xl {
  margin-bottom: 64px;
}

.margin-left-sm {
  margin-left: 8px;
}

.margin-left-base {
  margin-left: 16px;
}

.margin-left-md {
  margin-left: 24px;
}

.margin-right-sm {
  margin-right: 8px;
}

.margin-right-base {
  margin-right: 16px;
}

.margin-right-md {
  margin-right: 24px;
}

.padding-top-sm {
  padding-top: 8px;
}

.padding-top-base {
  padding-top: 16px;
}

.margin-bottom-base-md-down {
  @include media-breakpoint-down(md) {
    margin-bottom: 16px;
  }
}
