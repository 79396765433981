valio-cx-order-history {
  padding-bottom: 64px;

  .page-header {
    @include valio-page-header();

    .page-header-title {
      text-align: center;
    }
  }

  .order-header {
    @include valio-link-bold-18();
  }

  .search-slot {
    @include media-breakpoint-down(sm) {
      padding: 0 6px;
      margin-bottom: 16px;
    }
  }

  .print-button {
    @include valio-link-inv();
    font-weight: bold;

    .valio-icon {
      display: inline-block;
      position: absolute;
      left: -10px;
      bottom: -7px;
    }
  }

  .cart-details-wrapper {
    .cart-entry-block {
      valio-cx-cart-item {
        .product-image-description {
          .cx-image-container {
            min-width: 43px;

            cx-media {
              img {
                max-height: 40px;
              }

              &.is-missing[format=cartIcon] {
                background-size: 40px;
                min-height: 43px;
              }
            }
          }
        }
      }
    }
  }
}


