valio-cx-product-carousel {
  width: 100%;
  margin: 0 -8px;

  valio-cx-carousel {
    padding: 0 8px;
  }
}

valio-cx-carousel {
  @include valio-flex-box();
  @include valio-flex-direction-column();
  @include valio-flex-1();
  --cx-speed: 0.5;

  .product-container-header {
    @include valio-product-carousel-header();
  }

  .carousel-panel {
    @include valio-scrollbar();
    position: relative;

    .carousel-scroll {
      &.show-scroll {
        overflow-x: auto;
        overflow-y: hidden;
        scroll-behavior: smooth;
        margin-bottom: 16px;

        .carousel-items {
          display: block;
          white-space: nowrap;
          height: 338px;

          valio-cx-banner {
            width: 384px;

            @include media-breakpoint-down(sm) {
              display: none;
            }

            + valio-cx-product-grid-item {
              @include media-breakpoint-down(sm) {
                padding-left: 0;
                width: 184px;
              }
            }

            &:first-child {
              width: 376px;

              .banner-paddings {
                padding-left: 0;
              }
            }

            .banner-paddings {
              padding-bottom: 0;
            }
          }

          valio-cx-product-grid-item {
            width: 192px;
            margin-bottom: 0;

            &:first-child {
              padding-left: 0;
              width: 184px;
            }

            &:last-child {
              padding-right: 0;
              width: 184px;
            }

            .cx-product-name {
              white-space: normal;
            }
          }
        }
      }

      .carousel-items {
        valio-cx-product-grid-item {
          display: inline-block;
        }
      }
    }

    > button {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: +1;
      padding: 0;

      &.previous,
      &.next {
        background-color: var(--cx-color-transparent);
        border: none;
        width: 32px;

        .valio-icon {
          @include valio-border-radius($valio-btn-border-radius);
          display: block;
          background-color: var(--cx-color-dark-purple);
          opacity: 0.75;
          padding: 16px;
          box-shadow: 0 0 20px rgba(45, 21, 115, 0.1);
        }

        &:disabled {
          opacity: 0;
        }

        &:not(:disabled):hover {
          .valio-icon {
            opacity: 1;
          }
        }
      }

      &.previous {
        left: -20px;
      }

      &.next {
        right: -20px;
      }

      &:focus {
        outline: none;
      }

      &:not(:disabled) {
        cursor: pointer;
      }
    }
  }
}

valio-cx-product-scroll-list {
  min-width: 0;

  .PartnerBannerProductSlot {
    &.partner-frontpage {
      valio-cx-product-carousel {
        display: block;
        margin: 0;

        .cx-product-container {
          display: block;

          valio-cx-carousel {
            padding: 0;
            margin: 0 -8px;

            .carousel-panel {
              .carousel-scroll {
                .carousel-items {
                  white-space: normal;
                  height: auto;
                }
              }
            }
          }
        }
      }
    }

    valio-cx-product-carousel {
      &.se-storefront-component {
        &:first-child {
          margin-top: 48px;
        }

        &:before {
          content: attr(data-smartedit-component-id);
        }
      }

      .cx-product-container {
        display: none;
      }
    }
  }
}
