valio-cx-shipping-address {
  .cart-header-wrapper {
    text-align: center;
    padding-bottom: 20px;

    button {
      @include valio-bold-link-mini-14();
      display: inline;
      width: auto;
      float: left;
    }
  }

  .cart-wrapper {
    background-color: var(--cx-color-white);
    margin-bottom: 16px;
    padding: 16px;

    @include media-breakpoint-up(md) {
      padding: 16px 24px;
    }

    @include media-breakpoint-up(lg) {
      padding: 16px 32px;
    }

    &.shipping-address {
      padding: 40px 16px 32px;

      @include media-breakpoint-up(md) {
        padding: 40px 40px 48px;
      }

      @include media-breakpoint-up(lg) {
        padding: 40px 48px 48px;
      }
    }

    h3 {
      padding-bottom: 48px;

      @include media-breakpoint-down(md) {
        @include valio-H3-24();
      }

      @include media-breakpoint-down(sm) {
        padding-bottom: 40px;
      }
    }

    .section-row {
      @include valio-flex-direction-column();
      margin: 0 -32px;

      @include media-breakpoint-up(lg) {
        @include valio-flex-direction-row();
      }

      .section-column {
        padding: 0 32px;

        @include media-breakpoint-up(lg) {
          width: 50%;
        }
      }
    }

    .section {
      margin-bottom: 40px;

      .section-header {
        @include valio-link-bold-small-16();
        padding-bottom: 16px;
        margin-bottom: 24px;
        border-bottom: 1px solid var(--cx-color-iris);
      }

      .address-customer {
        margin-bottom: 32px;
      }

      .address-delivery {
        margin-bottom: 48px;
      }

      .address-label {
        @include valio-copy-mini-14();
        margin-bottom: 8px;
      }

      .address-value {
        @include valio-bold-link-mini-14();
      }
    }

    @include valio-cart-header-products-totals-mixin();

    .totals {
      .cx-price {
        @include media-breakpoint-down(sm) {
          @include valio-link-bold-18();
        }
      }
    }
  }
}

valio-cx-shipping-address-details {
  .delivery-slots,
  .delivery-modes {
    white-space: nowrap;

    @include media-breakpoint-down(sm) {
      white-space: normal;
    }

    .delivery-slot,
    .delivery-mode {
      cursor: pointer;
      display: inline-block;
      border-top: 1px solid var(--cx-color-text);
      border-bottom: 1px solid var(--cx-color-text);
      border-left: 1px solid var(--cx-color-text);
      padding: 0 16px;
      height: $valio-input-height-lg;
      line-height: $valio-input-height-lg;

      @include media-breakpoint-down(sm) {
        padding: 0 8px;
      }

      &.selected {
        background-color: var(--cx-color-light-purple);
      }
    }

    :first-child {
      @include valio-border-radius($valio-btn-border-radius 0 0 $valio-btn-border-radius);
      border-left: 1px solid var(--cx-color-text);
      border-right: none;
    }

    :last-child {
      @include valio-border-radius(0 $valio-btn-border-radius $valio-btn-border-radius 0);
      border-right: 1px solid var(--cx-color-text);
      border-left: 1px solid var(--cx-color-text);
    }
  }

  .shipping-address {
    valio-calendar-date-component {
      .calendar {
        .calendar-wrapper {
          .calendar-table {
            div {
              &.day {
                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.pickup-dot {
  @include valio-border-radius(50%);
  height: 16px;
  width: 16px;
  display: inline-block;
  margin: 0 8px;
  background-color: var(--cx-color-blueberry);
}

.submit-type-block {
  background-color: var(--cx-color-white);
  margin-top: 24px;
  padding: 32px 48px;

  @include media-breakpoint-down(md) {
    margin-top: 16px;
    padding: 40px;
  }

  @include media-breakpoint-down(sm) {
    padding: 40px 16px;
  }

  h3 {
    @include media-breakpoint-down(md) {
      @include valio-H3-24();
    }
  }

  .invoice-slot {
    @include valio-border-radius($valio-btn-border-radius);
    background-color: var(--cx-color-light-purple);
    text-align: center;
    padding: 24px 16px 32px;

    @include media-breakpoint-down(md) {
      padding: 24px 16px;
    }

    .submit-type-header {
      @include valio-link-bold-small-16();
      padding-bottom: 16px;
    }

    .submit-type-text {
      width: 65%;
      margin: auto;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }
}

valio-cx-review-submit-rows {
  @include valio-copy-mini-14();

  .submit-title {
    @include valio-link-bold-18();
  }

  .cart-entry-block {
    &:last-child {
      .cx-item-list-row {
        .cx-item-list-items {
          &:last-child {
            .cx-item {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
