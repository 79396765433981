valio-deliverynote-details {
  .page-header {
    @include valio-page-header();

    .page-header-title {
      text-align: center;

      h1 {
        padding: 0 0 40px;
      }

      p {
        @include valio-copy-18();
        margin: 0;
      }
    }
  }

  .delivery-note-list {
    .week-picker {
      @include valio-flex-box();
      @include valio-justify-content(flex-end);

      @include media-breakpoint-down(sm) {
        @include valio-justify-content(center);
      }

      .week-scoller {
        margin-bottom: 16px;

        @include media-breakpoint-up(sm) {
          margin-right: 24px;
        }

        .date {
          @include valio-bold-link-mini-14();
        }
      }
    }

    .date-picker-row {
      @include media-breakpoint-down(sm) {
        margin: 0 8px 16px;
      }

      valio-datepicker-input {
        width: 100%;
      }

      .search-deliverynote {
        margin-right: 16px;

        @include media-breakpoint-down(md) {
          margin-right: 0;
        }
      }

      .clear-selection-btn {
        @include valio-bold-link-mini-14();
        @include valio-link();
      }

      .week-picker {
        .week-scoller {
          @include media-breakpoint-down(sm) {
            margin-top: 32px;
          }
        }
      }
    }

    .table-header {
      @include valio-link-bold-18();
      background-color: var(--cx-color-light-peach);
      height: $valio-input-height-lg;
      line-height: $valio-input-height-lg;
      padding: 0 24px;

      @include media-breakpoint-down(sm) {
        padding: 0 8px;
      }
    }

    .table-content {
      @include valio-copy-mini-14();
      background: var(--cx-color-white);
      padding: 0 32px;

      @include media-breakpoint-down(sm) {
        padding: 0;
        overflow: hidden;
      }

      .table-header {
        margin: 0 -32px;

        @include media-breakpoint-down(sm) {
          margin: 0;
        }
      }

      .no-delivery-notes {
        height: $valio-input-height-lg;
        line-height: $valio-input-height-lg;
      }
    }

    .table-footer {
      margin: 32px 0 48px;
    }
  }
}
