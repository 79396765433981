valio-cx-product-grid-item {
  margin-bottom: 16px;

  .valio-cx-product-grid-item {
    @include valio-border-radius($valio-btn-border-radius);
    background-color: var(--cx-color-white);
    padding: 12px;
    min-width: 176px;
    width: 100%;
    height: 338px;
    overflow: hidden;
    position: relative;
    box-shadow: 6px 6px 20px rgba(45, 21, 115, 0.1);

    &:hover {
      box-shadow: 6px 6px 20px rgba(229, 215, 212, 0.5);
    }

    &.closing-time-passed {
      opacity: 0.8;
    }

    .cx-product-image-container {
      .cx-product-image-margin {
        @include valio-border-radius($valio-btn-border-radius);
        background-color: var(--cx-color-product-image-background);
        height: 140px;
        margin: -8px -8px 16px -8px;
        padding: 8px;

        cx-media {
          text-align: center;
          height: 124px;

          &.is-missing {
            &[format='product'] {
              min-height: 140px;
              background-size: 140px;
            }
          }

          img {
            @include valio-border-radius($valio-btn-border-radius);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            object-fit: contain;
            height: 100%;
          }
        }
      }

      .product-campaign-banner {
        @include valio-bold-link-mini-14();
        @include valio-border-radius(0 $valio-btn-border-radius $valio-btn-border-radius 0);
        color: var(--cx-color-white);
        background-color: var(--cx-color-black);
        position: absolute;
        height: 24px;
        left: 0;
        top: 107px;
        padding: 3px 9px;
      }

      .product-icon {
        position: absolute;
        top: 127px;

        &:first-child {
          right: 12px;
        }

        &:nth-child(2) {
          right: 52px;
        }

        &:nth-child(3) {
          right: 92px;
        }

        &:nth-child(4) {
          right: 132px;
        }
      }
    }

    .cx-product-code {
      @include valio-tiny-12();
      height: 14px;
      margin-bottom: 8px;
      white-space: nowrap;
      position: relative;

      .code {
        min-width: 48px;
        max-width: 60%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: bottom;

        cx-media {
          height: 32px;
          position: absolute;
          top: -8px;
        }
      }
    }

    .cx-product-name {
      @include valio-bold-link-mini-14();
      color: var(--cx-color-text);
      display: block;
      height: 51px;
      margin-bottom: 8px;
      overflow: hidden;

      em {
        font-style: normal;
      }
    }

    .product-delivery-period {
      @include valio-tiny-10();
      @include valio-border-radius($valio-btn-border-radius);
      background: var(--cx-color-secondary);
      padding: 0 8px;
      height: 32px;
      line-height: 32px;
      margin-bottom: 8px;
      white-space: nowrap;
      text-align: center;
    }

    .cx-product-unit {
      @include valio-tiny-12();
      height: 32px;
      margin-bottom: 8px;
      white-space: nowrap;

      .cx-product-unit-row {
        margin-bottom: 0;
      }
    }

    valio-cx-add-to-cart {
      margin-top: 4px;

      .quantity {
        position: absolute;
        left: 12px;
        right: 12px;
        bottom: 12px;
        background-color: var(--cx-color-white);

        .info {
          display: none;
        }
      }

      .btn-cart {
        .btn-text-quantity {
          @include valio-link-bold-18();
        }

        .btn-text-unit {
          @include valio-tiny-10();
        }
      }
    }
  }
}

valio-cx-product-price {
  max-width: 65%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;

  .price,
  .campaign-price,
  .list-price {
    display: inline-block;
  }

  .price,
  .campaign-price {
    @include valio-H1-38();
    @include valio-supsub();

    .supsub {
      sup {
        @include valio-link-bold-small-16();
        top: 4px;
      }

      sub {
        @include valio-copy-mini-14();
        color: var(--cx-color-text);
        top: 0;
      }
    }
  }

  .price {
    color: var(--cx-color-text);
  }

  .campaign-price {
    color: var(--cx-color-alarm-discount);
    margin-right: 8px;
  }

  .list-price {
    @include valio-tiny-10();
    text-decoration: line-through;
  }

  &:empty {
    display: none;
  }

  &:not(:empty) {
    + valio-cx-add-to-cart {
      float: right;
    }
  }
}

valio-last-purchases,
valio-top-sellers,
valio-bought-together {
  valio-cx-product-price {
    &:empty {
      + valio-cx-add-to-cart {
        float: right;
      }
    }
  }
}

.product-tooltip-templ {
  @include valio-copy-mini-14();
  display: none;
  width: 480px;
  height: auto;
  padding: 16px;
  position: absolute;
  z-index: 80;
  right: -472px;
  background-color: var(--cx-color-white);
  box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.1);
  pointer-events: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  &.clickable {
    pointer-events: initial;
    top: 0;
    left: 400px;

    @include media-breakpoint-up(xl) {
      left: 600px;
    }
  }

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: +1;
  }

  .cx-image-container {
    @include valio-border-radius($valio-btn-border-radius);
    background-color: var(--cx-color-product-image-background);
    height: 140px;
    padding: 8px;

    cx-media {
      text-align: center;
      height: 124px;

      img {
        @include valio-border-radius($valio-btn-border-radius);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        object-fit: contain;
      }
    }
  }

  valio-cx-product-price {
    width: 40%;
  }

  valio-cx-add-to-cart {
    position: relative;
    width: 60%;
    text-align: right;
    margin-top: 4px;

    .quantity {
      position: absolute;
      left: 0;
      right: 0;
    }

    .btn-cart {
      .btn-text-quantity {
        @include valio-link-bold-18();
      }

      .btn-text-unit {
        @include valio-tiny-10();
      }
    }
  }
}

valio-cx-product-summary {
  .btn-cart {
    width: auto;
    padding: 8px 24px;
    line-height: 24px;
  }
}
