valio-cx-item-counter,
.valio-cx-item-counter {
  .cx-counter {
    @include valio-border-radius($valio-btn-border-radius);
    background: var(--cx-color-white);
    border: 1px solid var(--cx-color-dark);
    position: relative;
    display: block;
    height: $valio-input-height-sm;
    line-height: $valio-input-height-sm;

    &.cx-counter-lg {
      height: $valio-input-height-lg;
      line-height: $valio-input-height-lg;

      .cx-counter-content {
        .cx-counter-value,
        .cx-counter-unit {
          height: $valio-input-content-lg;
          line-height: $valio-input-content-lg;
          width: 50%;
        }
      }

      .cx-counter-action {
        height: $valio-input-content-lg;
        line-height: $valio-input-content-lg;
      }
    }

    &.cx-counter-transparent {
      background-color: var(--cx-color-transparent);
    }

    &.cx-counter-suggestive {
      display: inline-block;
      min-width: 112px;
      vertical-align: top;
    }

    &.cx-counter-cart-item {
      display: inline-block;
      min-width: 120px;
      margin-right: 8px;
    }

    &.cx-counter-product-detail {
      min-width: 192px;
    }

    &.cx-counter-product-card {
      .cx-counter-content {
        .cx-counter-value {
          @include valio-copy-18();
          color: var(--cx-color-text);
          padding-right: 0;
          text-align: center;
        }

        .cx-counter-unit {
          display: none;
        }
      }
    }

    &.small-text {
      .cx-counter-content {
        @include valio-copy-mini-14();
      }
    }

    .cx-counter-content {
      position: absolute;
      left: 25%;
      right: 25%;
      height: 100%;
      text-align: center;

      .cx-counter-value,
      .cx-counter-unit {
        display: inline-block;
        height: $valio-input-content-sm;
        line-height: $valio-input-content-sm;
        width: 50%;
        vertical-align: top;
      }

      .cx-counter-value {
        text-align: right;
        border: none;
        padding: 0 8px 0 0;
        background-color: var(--cx-color-transparent);
      }

      .cx-counter-unit {
        text-align: left;
      }
    }

    .cx-counter-action {
      @include valio-border-radius($valio-btn-border-radius);
      position: absolute;
      height: $valio-input-content-sm;
      line-height: $valio-input-content-sm;
      width: 25%;
      color: var(--cx-color-primary);
      background-color: var(--cx-color-light-purple);
      font-size: 22px;
      cursor: pointer;
      border: none;
      padding: 0;

      &.btn-left {
        left: 0;
      }

      &.btn-right {
        right: 0;
      }

      &:hover,
      &:active,
      &:focus {
        color: var(--cx-color-white);
        background-color: var(--cx-color-primary);
      }

      &:disabled {
        pointer-events: none;
        color: var(--cx-color-gray);
        cursor: not-allowed;
      }
    }
  }
}
