@media print {
  valio-cx-order-history {
    .search-slot {
      display: none;
    }

    .order-row {
      &.print {
        display: block;
      }

      .date-toggle,
      .dot {
        display: none !important;
      }

      .header-info-slot {
        .total-weight,
        .header-product-type {
          border: none;
          padding: 0 0 10px;

          .payment-type {
            @include valio-copy-mini-14();
          }

          .totals {
            .cx-price {
              @include valio-copy-mini-14();
            }
          }
        }
      }
    }
  }
}
