.ContentPage1Template {
  @include valio-copy-18();
  max-width: $valio-page-md-max-width;
  padding: 40px 48px;
  margin: auto auto 128px auto;
  background-color: var(--cx-color-white);
  box-shadow: 0 0 44px rgba(0, 0, 0, 0.09);

  @include media-breakpoint-up(lg) {
    padding: 80px 144px;
  }

  @include media-breakpoint-down(sm) {
    margin: auto auto 40px;
  }

  cx-page-slot {
    &.Section2A,
    &.Section2B {
      display: block;
    }
  }

  cx-paragraph {
    h2 {
      padding: 2rem 0;
    }
  }
}
