valio-cx-mini-cart {
  height: $valio-input-height-lg;
  margin-right: 16px;

  @include media-breakpoint-down(md) {
    @include valio-flex-box();
    @include valio-align-items(center);
    margin-right: 0;
  }

  &:hover {
    .cart-text {
      text-decoration: underline;
    }
  }

  a {
    @include valio-flex-box();
    @include valio-justify-content(space-around);
    color: var(--cx-color-text);
    background: var(--cx-color-white);
    width: auto;
    height: auto;
    position: relative;

    .cart-text {
      @include valio-tiny-12();
      position: absolute;
      top: 32px;
      white-space: nowrap;
      margin-top: -1px;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .count {
      @include valio-tiny-12();
      @include valio-border-radius(30px);
      color: var(--cx-color-white);
      background: var(--cx-color-primary);
      padding: 0 6px;
      text-align: center;
      position: absolute;
      top: -5px;
      right: -10px;
      align-self: center;
    }
  }
}
