valio-deliverynote-reclamation {
  .delivery-info {
    @include valio-border-radius($valio-btn-border-radius);
    padding: 16px 16px 0;
    margin: 0 0 16px;
    background-color: var(--cx-color-secondary);

    @include media-breakpoint-up(lg) {
      padding: 32px 48px 8px;
      margin: 0 0 24px;
    }
  }

  .reclamation-item {
    text-align: left;

    @include media-breakpoint-down(sm) {
      border-bottom: 1px solid var(--cx-color-iris);
      margin-bottom: 16px;
    }

    .ng-select-focused {
      outline: none;
    }

    .ng-select {
      &.ng-select-opened {
        > .ng-select-container {
          .ng-arrow {
            top: 0;
          }
        }
      }

      &.ng-select-focused {
        &:not(.ng-select-opened) {
          > .ng-select-container {
            border-color: var(--cx-color-dark);
            box-shadow: none;
          }
        }
      }

      .ng-select-container {
        @include valio-copy-mini-14();
        border-color: var(--cx-color-dark);
        height: $valio-input-height-lg;

        .ng-value-container {
          .ng-input {
            top: initial;
          }
        }
      }

      .ng-dropdown-panel {
        width: auto;
        min-width: 100%;
        max-width: 912px;

        .ng-dropdown-panel-items {
          max-height: 280px;
        }
      }
    }

    .is-invalid {
      .ng-select-container,
      .form-control {
        border: 1px solid var(--cx-color-red);
      }
    }

    .add-remove-rows {
      span {
        font-size: 50px;
      }
    }
  }

  .reclamation-note {
    textarea {
      &.form-control {
        min-height: 140px;
        margin-bottom: 0;

        &:focus {
          background: var(--cx-color-white);
          border-color: var(--cx-color-dark);
          outline: none;
        }
      }
    }
  }

  .form-control {
    border-color: var(--cx-color-dark);
  }

  input[type='radio'] {
    border-color: var(--cx-color-dark);
    position: relative;
    margin: 0;

    &::after {
      height: 12px;
      width: 12px;
      left: 5px;
      top: 5px;
    }

    &:checked {
      border-color: var(--cx-color-dark);

      &::after {
        background-color: var(--cx-color-primary);
      }
    }
  }

  .footer-panel {
    border-top: 1px solid var(--cx-color-iris);
  }

  ng-select,
  input,
  textarea {
    .ng-invalid {
      @include valio-border-radius($valio-btn-border-radius);
      border: 1px solid var(--cx-color-red);
    }
  }
}
