.CartPageTemplate,
.MultiStepCheckoutSummaryPageTemplate,
.OrderConfirmationPageTemplate {
  @include valio-single-column-layout();

  cx-page-slot {
    &.TopContent,
    &.SideContent {
      @include valio-flex-0();
    }

    &.BodyContent,
    &.EmptyCartMiddleContent {
      @include valio-flex-1();
      width: 100%;

      .container {
        max-width: $valio-page-md-max-width;

        @include media-breakpoint-down(sm) {
          padding: 0;
        }
      }
    }
  }
}

.CartPageTemplate {
  cx-page-slot {
    &.EmptyCartMiddleContent {
      background-color: var(--cx-color-white);
      max-width: $valio-page-md-max-width;
      margin: 0 auto;
      padding: 16px;

      @include media-breakpoint-up(lg) {
        margin: 0 auto;
        padding: 64px 64px 32px;
      }

      cx-paragraph {
        padding: 0 48px;
      }
    }
  }
}
