valio-cx-login {
  .HeaderLinks {
    @include valio-justify-content(flex-start);

    > * {
      flex: none;
    }

    cx-link {
      cx-generic-link {
        @include valio-flex-box();

        a {
          padding: 20px 15px 25px 0;
        }
      }
    }
  }

  .btn-register {
    margin-right: 40px;

    @include media-breakpoint-up(lg) {
      margin-right: 16px;
    }

    @include media-breakpoint-up(xl) {
      margin-right: 48px;
    }
  }
}
