valio-faq-component {
  h2 {
    padding-left: 2rem;
  }

  .faq {
    @include valio-border-radius($valio-btn-border-radius);
    background-color: var(--cx-color-white);
    margin-bottom: 16px;

    .question-title {
      position: relative;
      overflow: hidden;
      padding: 16px 48px 16px 32px;
      border-bottom: 1px solid var(--cx-color-alice-blue);
      cursor: pointer;

      .valio-icon {
        position: absolute;
        top: 16px;
        right: 24px;
      }
    }

    .answer-section {
      padding: 3rem 3rem 2rem;
    }
  }
}
