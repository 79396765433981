.NewsPageTemplate {
  max-width: $valio-page-md-max-width;
  padding: 24px;
  margin: auto auto 128px auto;
  background-color: var(--cx-color-white);
  box-shadow: 0 0 44px rgba(0, 0, 0, 0.09);

  @include media-breakpoint-up(md) {
    padding: 24px 48px;
  }

  cx-page-slot {
    &.Section2A,
    &.Section2B,
    &.NewsSection {
      display: block;
    }
  }
}
