.ProductDetailsPageTemplate {
  padding: 0;

  cx-page-slot {
    &.Summary {
      display: block;
      justify-items: center;
      background: var(--cx-color-pink);
      padding: 0 0 4rem 0;
      margin-bottom: 0;
      position: relative;
      background: linear-gradient(90deg, var(--cx-color-pink) 70%, var(--cx-color-blueberry) 30%);

      @include media-breakpoint-down(sm) {
        background: var(--cx-color-white);
      }

      .container {
        max-width: $valio-page-md-max-width;
      }
    }

    &.Nutrition {
      background-color: var(--cx-color-white);

      @include media-breakpoint-up(md) {
        padding-top: 72px;
      }

      .container {
        max-width: $valio-page-md-max-width;
      }
    }
  }
}
