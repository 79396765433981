.ErrorPageTemplate {
  cx-page-slot {
    max-width: $valio-page-md-max-width;

    cx-paragraph {
      max-height: none;
      min-height: 560px;
      margin: 0 15% 0 55%;
      text-align: left;
      color: var(--cx-color-white)
    }

    valio-cx-background-image {
      position: absolute;
    }
  }
}
