.TopHeaderSlot {
  background: var(--cx-color-white);
  padding: 0 20px;

  @include media-breakpoint-down(md) {
    padding: 0 16px;
  }

  cx-paragraph {
    position: relative;

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      display: inline-block;
      content: "";
      height: 24px;
      width: 24px;
      background: no-repeat center center url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%0A%3Cpath%20d%3D%22M18.5%2019h-18c-0.178%200-0.342-0.094-0.432-0.248s-0.091-0.343-0.004-0.498l9-16c0.089-0.157%200.255-0.255%200.436-0.255s0.347%200.097%200.436%200.255l9%2016c0.087%200.155%200.085%200.344-0.004%200.498s-0.254%200.248-0.432%200.248zM1.355%2018h16.29l-8.145-14.48-8.145%2014.48z%22%20fill%3D%22%23E68A00%22%3E%3C%2Fpath%3E%0A%3Cpath%20d%3D%22M9.5%2014c-0.276%200-0.5-0.224-0.5-0.5v-5c0-0.276%200.224-0.5%200.5-0.5s0.5%200.224%200.5%200.5v5c0%200.276-0.224%200.5-0.5%200.5z%22%20fill%3D%22%23E68A00%22%3E%3C%2Fpath%3E%0A%3Cpath%20d%3D%22M9.5%2017c-0.276%200-0.5-0.224-0.5-0.5v-1c0-0.276%200.224-0.5%200.5-0.5s0.5%200.224%200.5%200.5v1c0%200.276-0.224%200.5-0.5%200.5z%22%20fill%3D%22%23E68A00%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
      vertical-align: middle;
    }

    > p {
      margin: 8px 0;
      padding-left: 32px;

      p {
        margin: 0;
      }
    }

    p,
    b,
    strong,
    span {
      line-height: 1;
    }
  }
}

header {
  background-color: var(--cx-color-white);
  border-top: 5px solid var(--cx-color-primary);
  color: var(--cx-color-dark);

  &:not(:empty) {
    height: 90px; // button height 53px + top and bottom paddings 16px + purple border top 5px

    @include media-breakpoint-up(sm) {
      height: 106px; // button height 53px + top and bottom paddings 24px + purple border top 5px
    }
  }

  .header {
    @include valio-justify-content(space-between);
    position: relative;
    padding-left: 0;
    padding-right: 0;
    max-width: none;
    flex-wrap: nowrap;
  }

  &.is-expanded {
    .PreHeader {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 65px;
      height: 65px;
      background-color: var(--cx-color-light-peach);
      z-index: 500;

      .cx-hamburger {
        &.is-active {
          margin-left: 0;
          padding: 20px;
          height: 65px;

          .hamburger-box {
            width: 25px;
            height: 25px;
          }
        }

        .hamburger-inner {
          &,
          &:before,
          &:after {
            background-color: var(--cx-color-text);
          }
        }
      }
    }

    .navigation {
      top: 0;
      bottom: -248px;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 400;
      height: auto;

      @include media-breakpoint-up(lg) {
        display: none;
      }

      .NavigationBar,
      .SiteLinks {
        margin-right: 72px;
      }

      .NavigationBar {
        background-color: var(--cx-color-white);
        border-bottom: 1px solid var(--cx-color-line);
      }

      .SiteLinks {
        background-color: var(--cx-color-cloud);
        padding: 32px 0 8px 0;
        box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.1);

        cx-link {
          padding: 0;

          &:first-child {
            display: none;
          }

          &:last-child {
            display: block;
          }

          cx-generic-link {
            display: block;
            margin: 0 24px 24px 24px;

            a {
              @include valio-copy-small-16();
              padding: 0;
              margin: 0;
            }
          }
        }

        valio-header-news {
          width: 100%;
        }

        valio-cx-site-context-selector {
          display: block;
          width: 100%;
          padding: 0 24px 24px 24px;

          .valio-site-context-selector {
            @include valio-copy-mini-14();
            line-height: inherit;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      &:after {
        content: '';
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: var(--cx-color-text);
        opacity: 0.6;
        z-index: 300;
      }
    }
  }

  &.is-anonymous {
    .SiteLinks {
      cx-link {
        @include media-breakpoint-up(lg) {
          padding-right: 16px;
        }

        @include media-breakpoint-up(xl) {
          padding-right: 40px;
        }
      }

      valio-cx-site-context-selector {
        display: inline-block;

        @include media-breakpoint-up(xl) {
          margin-left: 16px;
        }

        .valio-site-context-selector {
          @include valio-copy-mini-14();
          line-height: inherit;

          @include media-breakpoint-up(lg) {
            margin: 8px;
          }
        }
      }
    }
  }

  &.is-signed-in {
    .SiteLinks {
      valio-cx-site-context-selector {
        display: none;
      }
    }
  }

  .SiteContext,
  .SiteLinks,
  .SiteLogin {
    @include media-breakpoint-down(md) {
      background-color: var(--cx-color-white);
    }
  }

  .SiteContext,
  .SiteLinks {
    color: var(--cx-color-dark);
    --cx-margin: 0;

    @include media-breakpoint-up(lg) {
      --cx-margin: 0.5rem 8px;
    }
  }

  .SiteLinks {
    @include valio-justify-content(flex-start);
    padding: 0.25rem 1rem 0.5rem;

    @include media-breakpoint-up(lg) {
      padding: 0;
      flex-wrap: nowrap;
    }

    cx-link {
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: auto;
        padding: 0 40px 0 0;
      }

      &:first-child {
        display: block;
      }

      a {
        @include valio-copy-small-16();

        @include media-breakpoint-down(md) {
          padding: 1rem;
        }
      }
    }
  }

  .SiteLogin {
    padding: 16px 24px 16px 0;

    @include media-breakpoint-up(sm) {
      padding: 24px 32px 24px 0;
    }
  }

  .PreHeader {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .SiteLogo {
    @include media-breakpoint-up(lg) {
      margin: 0 48px;
    }

    @include media-breakpoint-down(md) {
      @include valio-flex-1();
      @include valio-justify-content(flex-start);
      width: auto;
      padding: 0;
      margin: 0;
    }

    cx-banner {
      a {
        display: inline-block;
      }
    }

    cx-media {
      max-width: 82px;
      max-height: $valio-input-height-sm;

      @include media-breakpoint-up(sm) {
        max-width: 101px;
        max-height: 49px;
      }
    }
  }

  .cx-hamburger {
    display: block;
    padding: 16px;
    margin: 0 8px;

    @include media-breakpoint-up(sm) {
      margin: 0 16px;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }

    .hamburger-inner {
      &,
      &:before,
      &:after {
        background-color: var(--cx-color-text);
      }
    }
  }
}

.SimpleHeader {
  &.has-components {
    @include valio-flex-box();
    @include valio-align-content(center);
    height: 85px; // mobile header height 90px - purple border top 5px
    width: 82px;
    margin: 0 32px;

    @include media-breakpoint-up(sm) {
      height: 101px; // header height 106px - purple border top 5px
      width: 101px;
      margin: 0 48px;
    }

    @include media-breakpoint-up(lg) {
      margin: 0 64px;
    }

    &:empty {
      display: none;
    }

    cx-banner {
      @include valio-flex-box();
      margin: auto;

      @include media-breakpoint-down(md) {
        height: $valio-input-height-sm;
      }
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

cx-site-context-selector {
  display: none;
}

valio-cx-site-context-selector {
  display: none;

  @include media-breakpoint-up(lg) {
    display: inline-block;
  }

  .valio-site-context-selector {
    line-height: $valio-input-height-lg;
    margin-right: 24px;

    @include media-breakpoint-up(xl) {
      margin-right: 56px;
    }

    .site-context-item {
      text-transform: uppercase;

      &.selected {
        display: none;

        &:first-child {
          + .site-context-item {
            &:before {
              display: none;
            }
          }
        }
      }

      + .site-context-item {
        &:before {
          content: "|";
          margin: 0 8px;

          @include media-breakpoint-up(xl) {
            margin: 0 16px;
          }
        }
      }
    }
  }
}
