valio-cx-partner-card {
  width: 100%;
  float: left;
  display: inline-block;

  @include media-breakpoint-up(md) {
    width: 50%;
  }

  @include media-breakpoint-up(lg) {
    width: 33.3%;
  }

  .partner-card {
    @include valio-border-radius($valio-btn-border-radius);
    height: 488px;
    margin: 0 0 16px;
    padding: 40px 40px 32px;
    background-color: var(--cx-color-white);
    box-shadow: 0 4px 34px rgba(0, 0, 0, 0.05);
    text-align: center;

    @include media-breakpoint-up(md) {
      margin: 0 8px 16px;
    }

    &.is-anonymous {
      height: 360px;
    }

    img {
      height: 96px;
    }

    .head-text {
      @include valio-link-bold-18();
      padding: 16px 0;
    }

    .card-text {
      @include valio-copy-small-16();
      height: 128px;
      margin-bottom: 40px;

      span {
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        max-height: 128px;
      }
    }

    .search-text {
      @include valio-bold-link-mini-14();
    }

    .existing-contract {
      @include valio-copy-small-16();
      max-width: 240px;
      margin: auto;
    }
  }
}

valio-cx-partner-apply {
  @include valio-copy-18();
  width: 100%;
  max-width: $valio-page-sm-max-width;
  margin: auto;

  .page-header {
    .page-header-title {
      .partner-icon {
        margin-bottom: 24px;

        cx-media {
          img {
            max-width: 144px;
            max-height: 96px;
          }
        }
      }

      h1 {
        margin-bottom: 40px;
      }

      button {
        margin-top: 32px;
      }
    }
  }

  .partner-apply-content {
    @include valio-border-radius($valio-lg-border-radius);
    box-shadow: 0 0 44px rgba(0, 0, 0, 0.09);
    background-color: var(--cx-color-white);
    margin-bottom: 80px;

    .content-center {
      padding: 32px 16px 40px 16px;

      @include media-breakpoint-up(sm) {
        padding: 48px 100px 80px 100px;
      }

      @include media-breakpoint-up(lg) {
        padding: 48px 248px 80px 248px;
      }

      .label-content {
        &.header {
          @include valio-link-bold-small-16();
          margin: 32px 0 16px 0;

          &.info {
            margin: 48px 0 16px 0;
          }
        }
      }

      .subtitle {
        @include valio-copy-mini-14();
      }

      textarea {
        margin-bottom: 32px;
      }

      .partner-apply-buttons {
        @include media-breakpoint-down(sm) {
          @include valio-flex-box();
          @include valio-flex-direction-column();
        }
      }
    }
  }
}
