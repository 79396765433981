.modal {
  .modal-dialog {
    @include media-breakpoint-down(sm) {
      margin: 0 !important;
    }
  }

  .valio-modal {
    &.modal-dialog {
      &.modal-dialog-centered {
        min-width: 100%;
        margin: 0;

        @include media-breakpoint-down(sm) {
          height: 100%;
          align-items: unset !important;
        }
      }

      .modal-content {
        @include valio-border-radius($valio-btn-border-radius);
        border: none;
        text-align: center;
        padding: 16px;
        overflow: auto;
        margin: auto;

        @include media-breakpoint-up(md) {
          display: block;
          margin: auto;
          max-width: 500px;
        }

        @include media-breakpoint-up(lg) {
          padding: 48px;
        }

        .modal-header {
          padding: 16px 0 32px;
          display: inline-block;
          border-bottom: none;

          .cx-facet-modal-title {
            @include valio-link-bold-18();
            color: var(--cx-color-text);
          }
        }

        valio-cx-logout-modal {
          .close {
            bottom: 20px;
            left: 20px;
          }
        }

        &.wide-modal {
          width: 80%;
          max-width: 80%;
          min-height: 500px;
          height: auto;

          @include media-breakpoint-up(md) {
            width: 90%;
            max-width: 90%;
          }

          @include media-breakpoint-up(lg) {
            width: 960px;
            padding: 24px;
          }

          .container-center {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &.medium-modal {
          width: 60%;
          max-width: 60%;

          .container-center {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  &.cx-spinner,
  &.modal-logout {
    &.show {
      display: block;
    }
  }

  .close {
    opacity: 1;
  }
}

#valio-spinner {
  z-index: 9999999999;
}

.valio-spinner-loader {
  font-size: 10px;
  overflow: hidden;
  -webkit-animation: load8 var(--cx-spinner-animation-time) infinite linear;
  animation: load8 var(--cx-spinner-animation-time) infinite linear;
}
