valio-cx-cart-details,
valio-cx-review-submit-invoice,
valio-cx-review-submit-credit,
valio-cx-order-confirmation-items,
valio-cx-shipping-address {
  padding-bottom: 64px;

  .cart-header {
    @include valio-page-header();

    @include media-breakpoint-down(md) {
      height: 70px;
    }
  }

  valio-cart-header,
  valio-checkout-header {
    .cart-header-wrapper {
      h1 {
        display: inline;
      }

      label {
        @include valio-bold-link-mini-14();
      }
    }

    .header-edit-slot {
      &.collapsed {
        display: none;
      }

      .message-wrapper {
        background: var(--cx-color-white);
        padding: 24px;
      }

      button {
        @include valio-bold-link-mini-14();
        display: inline;
        width: auto;
      }
    }
  }

  .cart-rows-for-date {
    margin-bottom: 32px;

    @include media-breakpoint-down(sm) {
      padding: 0 16px;
    }
  }

  .cart-rows-for-other-date {
    margin-top: 80px;
    margin-bottom: 32px;

    @include media-breakpoint-down(sm) {
      padding: 0 16px;
    }
  }

  .checkmark {
    background-color: var(--cx-color-peach) !important;
  }
}

.header-info-slot {
  @include valio-border-radius($valio-btn-border-radius);
  color: var(--cx-color-white);
  background-color: var(--cx-color-primary);
  padding: 8px 32px 8px 24px;

  @include media-breakpoint-down(md) {
    @include valio-border-radius(0);
    padding: 16px;
  }

  &.header-info-slot-cart {
    padding: 8px 8px 8px 24px;
  }

  &.mobile-header {
    background-color: var(--cx-color-medium-purple);
    padding-top: 0;
    padding-bottom: 0;

    .header-block {
      @include media-breakpoint-down(sm) {
        @include valio-bold-link-mini-14();
      }
    }
  }

  .date-toggle,
  .partner-toggle {
    cursor: pointer;
    display: inline-block;
    width: 32px;
  }

  .header-block {
    @include valio-copy-mini-14();
    white-space: nowrap;

    @include media-breakpoint-up(lg) {
      line-height: 20px;
    }

    .cart-item-status {
      @include valio-copy-mini-14();

      .dot {
        margin-right: 4px;
      }
    }

    .show-open-rows {
      white-space: normal;

      @include media-breakpoint-down(xs) {
        margin-top: 16px;
      }
    }

    .header-button {
      @include valio-link-inv();
      font-weight: bold;
    }

    .header-product-type {
      text-align: right;

      @include media-breakpoint-down(md) {
        text-align: left;
        padding: 16px 0;
        border-bottom: 1px solid var(--cx-color-gray);
      }

      &:last-child {
        @include media-breakpoint-down(md) {
          border-bottom: none;
        }
      }

      .payment-type {
        @include valio-copy-small-16();
        display: inline-block;

        @include media-breakpoint-up(md) {
          @include valio-link-bold-small-16();
        }

        @include media-breakpoint-up(lg) {
          @include valio-bold-link-mini-14();
        }
      }

      @include valio-cart-header-products-totals-mixin();
    }

    .total-weight {
      @include media-breakpoint-down(sm) {
        text-align: left;
        padding: 16px 0;
        border-bottom: 1px solid var(--cx-color-gray);
      }

      @include media-breakpoint-up(md) {
        text-align: right;
        white-space: nowrap;
      }
    }
  }
}

valio-date-view {
  .cx-date {
    @include valio-H1-38();
    line-height: $valio-input-height-sm;
    display: inline-block;

    @include media-breakpoint-down(md) {
      line-height: 34px;
    }

    @include valio-supsub();

    .supsub {
      padding-left: 8px;

      sup {
        @include valio-bold-link-mini-14();
        top: 0;
      }

      sub {
        @include valio-copy-mini-14();
        top: 0;
      }
    }
  }
}

valio-cx-shipping-address-details {
  display: block;
  margin-top: 16px;

  label {
    @include valio-copy-mini-14();
  }

  .shipping-details-slot {
    background: var(--cx-color-white);
    padding: 16px;

    @include media-breakpoint-up(md) {
      padding: 24px 32px;
    }

    .shipping-details-header {
      @include valio-link-bold-18();
      border-bottom: 1px solid var(--cx-color-iris);
      padding-bottom: 16px;
    }

    .shipping-details {
      padding: 24px 16px 16px;

      @include media-breakpoint-down(md) {
        padding: 24px 0 16px;
      }

      .shipping-detail-item {
        @include media-breakpoint-down(md) {
          margin-bottom: 24px;
        }

        &:last-child {
          @include media-breakpoint-down(md) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

valio-voucher {
  display: block;
  margin-top: 16px;

  .vouchers {
    background: var(--cx-color-white);
    padding: 16px;

    @include media-breakpoint-up(md) {
      padding: 24px 32px;
    }

    .vouchers-header {
      @include valio-link-bold-18();
      border-bottom: 1px solid var(--cx-color-iris);
      padding-bottom: 16px;
    }

    .voucher-details {
      padding: 24px 16px 16px;

      @include media-breakpoint-down(md) {
        padding: 24px 0 16px;
      }
    }

    .applied-vouchers {
      a {
        @include valio-link();
        @include valio-link-bold-small-16();
      }
    }
  }
}

valio-cx-cart-details-title {
  width: 100%;

  + valio-cx-cart-details-title {
    .cart-header-wrapper {
      margin-top: 16px;
    }
  }
}
