valio-cx-order-template-list {
  @include valio-side-panel-content();

  &{
    padding: 0;
  }

  @include media-breakpoint-up(md) {
    padding: 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 0;
  }

  valio-main-navigation-component {
    .main-navigation {
      .header-info-slot {
        width: 100%;
      }

      .main-navigation-slot {
        display: none;
      }
    }
  }

  .valio-sidebar {
    padding: 0 32px 40px 48px;

    @include media-breakpoint-down(md) {
      padding: 0 32px 40px 32px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 16px 40px 16px;
    }

    .order-templates-menu {
      @include valio-my-account-menu();

      ul {
        li {
          &:last-child {
            @include media-breakpoint-down(md) {
              border-bottom: none;
            }
          }
        }
      }

      .order-templates-title {
        border-bottom: none;
      }

      .delimiter {
        padding: 0 0 24px 64px;
        border-bottom: none;

        &:hover {
          text-decoration: none;
        }

        .template-item {
          padding: 8px 0;

          a {
            @include valio-copy-mini-14();

            &.active {
              @include valio-bold-link-mini-14();
            }
          }
        }
      }

      .order-templates-create {
        padding: 0 16px;
      }
    }
  }
}

valio-cx-order-template-grid,
valio-cx-order-template-content,
valio-cx-baseorder-content {
  .order-template-header {
    text-align: center;
    padding: 64px 0;

    h1 {
      padding: 0 0 40px;
    }

    p {
      @include valio-copy-18();
      margin: 0;

      b {
        @include valio-link-bold-18();
      }
    }
  }
}

valio-cx-order-template-grid {
  .order-template-grid {
    .grid-item {
      .grid-item-inner {
        @include valio-border-radius($valio-btn-border-radius);
        background-color: var(--cx-color-white);
        box-shadow: 6px 6px 20px rgba(229, 215, 212, 0.5);
        padding: 8px;
        margin-bottom: 16px;

        .grid-item-link {
          display: block;

          > .row {
            margin-left: -2px;
            margin-right: -2px;

            > [class*='col-'] {
              padding-right: 2px;
              padding-left: 2px;
            }
          }
        }

        .grid-footer {
          margin: 4px;

          .grid-footer-date,
          .grid-footer-products {
            @include valio-tiny-12();
            margin-bottom: 4px;
          }

          .grid-footer-products {
            &:before {
              display: inline-block;
              content: "|";
              margin: 0 8px;
            }
          }

          .grid-footer-name,
          .grid-footer-copy {
            display: block;
            margin-bottom: 40px;
          }

          .delete-icon {
            background-color: var(--cx-color-light-purple);
          }
        }
      }
    }
  }
}

valio-cx-order-template-content,
valio-cx-baseorder-content {
  .order-template-content {

    &.cart-details-wrapper {
      .cart-entry-block {
        .cx-item-list-row {
          .cx-item-list-items {
            .cx-item {
              margin: 0;

              @include media-breakpoint-up(lg) {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

valio-cx-order-template-content {
  .order-template-content {
    @include valio-border-radius($valio-btn-border-radius);
    background: var(--cx-color-white);
    box-shadow: 0 0 24px rgba(255, 236, 230, 0.6);
    padding: 24px;

    .subtitle {
      text-align: center;
      padding: 24px 0 16px;
    }

    .order-template-products {
      text-align: center;
      margin-bottom: 40px;
    }

    .order-template-empty {
      text-align: center;
      padding-top: 136px;
      padding-bottom: 144px;
    }

    .suggestive-row {
      border-bottom: 1px solid var(--cx-color-text);

      .ng-select {
        margin-bottom: 16px;
      }

      valio-suggestive-cx-searchbox {
        label {
          margin-bottom: 16px;
        }
      }
    }
  }
}

valio-cx-remove-order-template,
valio-cx-order-template-grid {
  .remove-entry-description {
    @include valio-copy-18();
    margin-bottom: 32px;
  }

  .remove-entry-content {
    @include valio-border-radius($valio-btn-border-radius);
    background: var(--cx-color-white);
    box-shadow: 6px 6px 20px rgba(229, 215, 212, 0.5);
    padding: 4px 4px 0;
    margin-bottom: 48px;
  }

  .cx-image-container {
    @include valio-border-radius($valio-btn-border-radius);
    background-color: var(--cx-color-product-image-background);
    height: 68px;
    padding: 8px;
    margin-bottom: 4px;

    cx-media {
      text-align: center;
      max-height: 52px;

      &.is-missing {
        background-size: 46px;
        min-height: 52px;
      }

      img {
        max-height: 52px;
      }
    }
  }
}

valio-cx-baseorder-content {
  .blocks {
    @include valio-border-radius($valio-btn-border-radius);
    background: var(--cx-color-white);
    border: 1px solid var(--cx-color-alarm-discount);
    box-sizing: border-box;
    box-shadow: 0 0 24px rgba(255, 236, 230, 0.6);
    margin-bottom: 16px;

    .header {
      background-color: var(--cx-color-alarm-discount);
      color: var(--cx-color-white);
      padding: 8px 32px;
    }

    .blocks-content {
      padding: 24px 32px;
    }

    .blocks-separator {
      border-bottom: 1px solid var(--cx-color-iris);
    }

    .title {
      padding: 8px;
    }

    .content {
      font-weight: bold;
      padding: 8px;
    }

    .link {
      cursor: pointer;
    }
  }

  .order-template-content {
    @include valio-border-radius($valio-btn-border-radius);
    padding: 24px 32px;
    min-height: 300px;

    .subtitle {
      text-align: center;
      padding: 24px 0 32px;
    }

    .divider {
      border-top: 1px solid var(--cx-color-dark);
      padding-top: 16px;
      margin-top: 16px;
    }

    .message {
      margin: 16px 0;
    }
  }
}

valio-baseorder-block-modal {
  .date-pickers {
    &.date-picker-right {
      valio-datepicker-input {
        .input-group {
          .input-label {
            ngb-datepicker {
              right: -37px; // btn-lg min-width 53px - input-label padding-right 16px
              left: auto !important;
            }
          }
        }
      }
    }

    valio-datepicker-input {
      .input-group {
        .input-label {
          min-width: auto;
        }
      }
    }
  }

  .email-check {
    border-bottom: 1px solid var(--cx-color-gray);
    padding-bottom: 16px;

    label {
      input {
        margin: 0;
        left: 8px;
      }
    }

    span {
      padding-left: 24px;
    }
  }

  .disclaimer {
    @include valio-border-radius($valio-btn-border-radius);
    padding: 16px;
    margin: 16px 0;
    background-color: var(--cx-color-nude);
    text-align: center;
  }

  textarea {
    @include valio-border-radius($valio-btn-border-radius);
    width: 100%;
  }
}
