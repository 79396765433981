/* Typography from VALO visual guidelines */
@mixin valio-H1-38() {
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 50px;
}

@mixin valio-H3-24() {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 125%;
}

@mixin valio-copy-18() {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
}

@mixin valio-link-bold-18() {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
}

@mixin valio-copy-small-16() {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
}

@mixin valio-link-bold-small-16() {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
}

@mixin valio-copy-mini-14() {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 125%;
}

@mixin valio-bold-link-mini-14() {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 125%;
}

@mixin valio-tiny-12() {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
}


/* Other font styles from Figma design */
@mixin valio-tiny-10() {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
}

@mixin valio-mobile-full-euros-32() {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 95%;
}


/* Other common font styles */
@mixin valio-link() {
  color: var(--cx-color-text);
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &.hover,
  &:active,
  &.active,
  &:focus,
  &.focus {
    color: var(--cx-color-text);
    text-decoration: underline;
    box-shadow: none;
    outline: 0;
  }

  &:disabled,
  &.disabled {
    color: var(--cx-color-gray);
    pointer-events: none;
  }
}

@mixin valio-link-inv() {
  color: var(--cx-color-white);
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &.hover,
  &:active,
  &.active,
  &:focus,
  &.focus {
    color: var(--cx-color-white);
    text-decoration: underline;
    box-shadow: none;
    outline: 0;
  }

  &:disabled,
  &.disabled {
    color: var(--cx-color-gray);
    pointer-events: none;
  }
}

@mixin valio-link-pseudos() {
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    box-shadow: none;
    outline: 0;
  }

  &:disabled {
    pointer-events: none;
  }
}
