valio-datepicker-input {
  .input-group {
    @include valio-flex-box();
    @include valio-border-radius($valio-btn-border-radius);
    position: relative;
    flex-wrap: nowrap;
    box-sizing: border-box;
    border-style: none;

    .input-label {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
      border-color: var(--cx-color-dark);
      min-width: 216px;
      width: 100%;

      &.has-value {
        border-right: none;
      }

      ngb-datepicker {
        left: -16px !important;

        @include media-breakpoint-down(sm) {
          right: -37px; // btn-lg min-width 53px - input-label padding-right 16px
        }

        .ngb-dp-months {
          @include media-breakpoint-down(sm) {
            display: block;
          }
        }
      }
    }
  }

  .ngb-dp-month-name {
    @include valio-link-bold-small-16();
    color: var(--cx-color-dark);
    background-color: var(--cx-color-white);
    line-height: $valio-input-height-lg;
    height: $valio-input-height-lg;
  }

  .ngb-dp-weekday {
    @include valio-tiny-12();
    height: $valio-input-height-lg;
    width: 46px;
    line-height: $valio-input-height-lg;
    text-align: center;
  }

  .ngb-dp-weekdays {
    @include valio-border-radius(0);
    border-bottom: 1px solid var(--cx-color-pink);
  }

  .ngb-dp-day {
    width: 46px;
    height: $valio-input-height-lg;
    border-top: 1px solid var(--cx-color-pink);
    padding: 8px 4px;

    @include media-breakpoint-down(sm) {
      padding: 8px 2px;
    }
  }

  // Weeks row overrides
  .ngb-dp-week {
    background-color: var(--cx-color-white) !important;
    padding: 0;
  }

  .bg-light {
    background-color: var(--cx-color-white);
  }

  .ngb-dp-week-number {
    @include valio-copy-small-16();
    color: var(--cx-color-dark) !important;
    width: 46px;
    height: $valio-input-height-lg;
    line-height: $valio-input-height-lg;
    text-align: center;
    border-right: 1px solid var(--cx-color-pink);
  }

  .ngb-dp-arrow-btn {
    width: 48px;
    padding: 0;
    margin: 0;
  }

  // Navigation row overrides
  .ngb-dp-header {
    background-color: var(--cx-color-white);
    border-style: none;
    padding: 0;
  }

  .ngb-dp-month:first-child .ngb-dp-week {
    padding-left: 0;
  }

  .ngb-dp-month:last-child .ngb-dp-week {
    padding-right: 0;
  }

  .ngb-dp-arrow {
    width: auto;
    height: auto;
  }

  .ngb-dp-navigation-chevron {
    border-width: .1em .1em 0 0;
    width: 8px;
    height: 8px;
  }
}
