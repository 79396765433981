valio-datepicker-day {
  .custom-day {
    @include valio-copy-18();
    @include valio-border-radius($valio-btn-border-radius);
    background-color: var(--cx-color-white);
    text-align: center;
    width: 38px;
    height: 36px;
    line-height: 36px !important;
  }

  .custom-day.disabled {
    color: var(--cx-color-gray);
    opacity: 0.5;
  }

  .custom-day.today, .custom-day.focused {
    @include valio-copy-18();
    // border:  1px solid var(--cx-color-pink);
    color: var(--cx-color-dark);
    opacity: 1;
  }

  .custom-day.has-content {
    @include valio-link-bold-18();
    color: var(--cx-color-dark);
    background-color: var(--cx-color-peach);
    opacity: 1;
  }

  .custom-day.selected, .custom-day:hover {
    @include valio-link-bold-18();
    color: var(--cx-color-white);
    background-color: var(--cx-color-purple);
    opacity: 1;
  }
}
