.cx-product-container {
  max-width: 100%;

  &.no-carousel,
  valio-cx-carousel {
    .row {
      margin-left: -8px;
      margin-right: -8px;

      > [class*='col-'] {
        padding-right: 8px;
        padding-left: 8px;

        > .row {
          margin-left: -8px;
          margin-right: -8px;
        }
      }
    }

    valio-cx-product-grid-item {
      width: 50%;

      @media (min-width: $valio-product-grid-full-breakpoint-xs) { // 2 product cards, no calendar
        width: 50%;
      }
      @media (min-width: $valio-product-grid-full-breakpoint-sm) { // 3 product cards, no calendar
        width: 33.333%;
      }
      @media (min-width: $valio-product-grid-full-breakpoint-md) { // 4 product cards, no calendar
        width: 25%;
      }
      @media (min-width: $valio-product-grid-full-breakpoint-lg) { // 5 product cards, no calendar
        width: 20%;
      }
      @media (min-width: $valio-product-grid-full-breakpoint-xl) { // 6 product cards, no calendar
        width: 16.666%;
      }
      @media (min-width: $valio-product-grid-full-breakpoint-xxl) { // 7 product cards, no calendar
        width: 14.285%;
      }
      @media (min-width: $valio-product-grid-full-breakpoint-xxxl) { // 8 product cards, no calendar
        width: 12.5%;
      }
      @media (min-width: $valio-product-grid-full-breakpoint-xxxxl) { // 9 product cards, no calendar
        width: 11.111%;
      }
    }

    valio-cx-banner {
      width: 100%;

      @media (min-width: $valio-product-grid-full-breakpoint-xs) { // 2 product cards, no calendar
        width: 100%;
      }
      @media (min-width: $valio-product-grid-full-breakpoint-sm) { // 3 product cards, no calendar
        width: 66.666%;
      }
      @media (min-width: $valio-product-grid-full-breakpoint-md) { // 4 product cards, no calendar
        width: 50%;
      }
      @media (min-width: $valio-product-grid-full-breakpoint-lg) { // 5 product cards, no calendar
        width: 40%;
      }
      @media (min-width: $valio-product-grid-full-breakpoint-xl) { // 6 product cards, no calendar
        width: 33.332%;
      }
      @media (min-width: $valio-product-grid-full-breakpoint-xxl) { // 7 product cards, no calendar
        width: 28.57%;
      }
      @media (min-width: $valio-product-grid-full-breakpoint-xxxl) { // 8 product cards, no calendar
        width: 25%;
      }
      @media (min-width: $valio-product-grid-full-breakpoint-xxxxl) { // 9 product cards, no calendar
        width: 22.222%;
      }
    }
  }
}


/* Add here page templates which have calendar side panel */
.LandingPage2Template,
.ProductListPageTemplate,
.ProductGridPageTemplate,
.SearchResultsListPageTemplate,
.SearchResultsGridPageTemplate {
  .cx-product-container {
    &.with-calendar {
      &.no-carousel,
      valio-cx-carousel {
        .row {
          margin-left: -8px;
          margin-right: -8px;

          > [class*='col-'] {
            padding-right: 8px;
            padding-left: 8px;

            > .row {
              margin-left: -8px;
              margin-right: -8px;
            }
          }
        }

        valio-cx-product-grid-item {
          width: 50%;

          @media (min-width: $valio-product-grid-breakpoint-xs) { // 2 product cards, no calendar
            width: 50%;
          }
          @media (min-width: $valio-product-grid-breakpoint-sm) { // 3 product cards, no calendar
            width: 33.333%;
          }
          @media (min-width: $valio-product-grid-breakpoint-md) { // 4 product cards, no calendar
            width: 25%;
          }
          @media (min-width: $valio-product-grid-breakpoint-lg) { // 2 product cards, with calendar
            width: 50%;
          }
          @media (min-width: $valio-product-grid-breakpoint-xl) { // 3 product cards, with calendar
            width: 33.333%;
          }
          @media (min-width: $valio-product-grid-breakpoint-xxl) { // 4 product cards, with calendar
            width: 25%;
          }
          @media (min-width: $valio-product-grid-breakpoint-xxxl) { // 5 product cards, with calendar
            width: 20%;
          }
          @media (min-width: $valio-product-grid-breakpoint-xxxxl) { // 6 product cards, with calendar
            width: 16.666%;
          }
          @media (min-width: $valio-product-grid-breakpoint-xxxxxl) { // 7 product cards, with calendar
            width: 14.285%;
          }
        }

        valio-cx-banner {
          width: 100%;

          @media (min-width: $valio-product-grid-breakpoint-xs) { // 2 product cards, no calendar
            width: 100%;
          }
          @media (min-width: $valio-product-grid-breakpoint-sm) { // 3 product cards, no calendar
            width: 66.666%;
          }
          @media (min-width: $valio-product-grid-breakpoint-md) { // 4 product cards, no calendar
            width: 50%;
          }
          @media (min-width: $valio-product-grid-breakpoint-lg) { // 2 product cards, with calendar
            width: 100%;
          }
          @media (min-width: $valio-product-grid-breakpoint-xl) { // 3 product cards, with calendar
            width: 66.666%;
          }
          @media (min-width: $valio-product-grid-breakpoint-xxl) { // 4 product cards, with calendar
            width: 50%;
          }
          @media (min-width: $valio-product-grid-breakpoint-xxxl) { // 5 product cards, with calendar
            width: 40%;
          }
          @media (min-width: $valio-product-grid-breakpoint-xxxxl) { // 6 product cards, with calendar
            width: 33.332%;
          }
          @media (min-width: $valio-product-grid-breakpoint-xxxxxl) { // 7 product cards, with calendar
            width: 28.57%;
          }
        }
      }
    }
  }
}


/* Add here page templates which have max-width and last purchases component */
.CartPageTemplate {
  .cx-product-container {
    valio-cx-product-grid-item {
      @media (min-width: $valio-page-md-max-width) {
        width: 14.285% !important;
      }
    }
  }
}
