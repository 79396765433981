.RegisterPageTemplate {
  padding: 0;

  cx-page-slot {
    width: 100%;
    max-width: $valio-page-sm-max-width;
    padding: 0;
    margin: auto;

    > * {
      max-width: var(--cx-flex-basis, 100%);
    }

    &.BodyContent {
      @include valio-flex-1();
      padding-bottom: 96px;

      @include media-breakpoint-down(md) {
        padding: 16px;
      }
    }

    &.BottomContent {
      @include valio-flex-0();
      max-width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    --cx-max-width: 100%;
  }
}
