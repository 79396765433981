valio-cx-product-contractual-switch {
  .form-check {
    padding: 0;
    margin: 0;
    text-align: left;

    label {
      display: block;
      position: relative;
      padding-left: 32px;
      margin-bottom: 0;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:hover {
        input {
          & ~ .checkmark {
            background-color: var(--cx-color-cloud);
          }
        }
      }

      .form-check-label {
        @include valio-copy-mini-14();
        padding-left: 0;

        a {
          @include valio-bold-link-mini-14();
          color: var(--cx-color-black);
        }
      }

      /* Hide the browser's default checkbox */
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked {
          & ~ .checkmark {
            background-color: var(--cx-color-white);

            &:after {
              display: block;
            }
          }
        }
      }

      /* Create a custom checkbox */
      .checkmark {
        @include valio-border-radius($valio-btn-border-radius);
        position: absolute;
        top: 6px;
        left: 0;
        height: 24px;
        width: 24px;
        border: 1px solid var(--cx-color-black);
        background-color: var(--cx-color-pink);

        &.is-invalid {
          border: 1px solid var(--cx-color-red);
        }

        /* Create the checkmark (hidden when not checked) */
        &:after {
          content: "";
          position: absolute;
          display: none;
          left: 8px;
          top: 3px;
          width: 8px;
          height: 14px;
          border: solid var(--cx-color-primary);
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }
}
