// VALO Button styles
.btn {
  @include valio-link-bold-small-16();
  @include valio-border-radius($valio-btn-border-radius);
  border-width: 1px;
  text-transform: initial;
  height: $valio-input-height-sm;
  line-height: $valio-input-height-sm;
  max-height: $valio-input-height-sm;
  min-height: $valio-input-height-sm;
  min-width: $valio-input-height-sm;
  padding: 0 24px;
  white-space: nowrap;

  &:hover,
  &.hover,
  &:active,
  &.active,
  &:focus,
  &.focus {
    -webkit-filter: none;
    filter: none;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:active,
      &.active {
        -webkit-filter: none;
        filter: none;

        &:focus,
        &.focus {
          box-shadow: none;
        }
      }
    }
  }

  &-primary {
    background-color: var(--cx-color-primary);
    border-color: var(--cx-color-primary);
    color: var(--cx-color-white);

    &:hover,
    &.hover,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: var(--cx-color-dark-purple);
      border-color: var(--cx-color-dark-purple);
    }

    &:disabled,
    &.disabled {
      background-color: var(--cx-color-iris);
      border-color: var(--cx-color-iris);
    }

    &:not(:disabled) {
      &:not(.disabled) {
        &:active,
        &.active {
          background-color: var(--cx-color-dark-purple);
          border-color: var(--cx-color-dark-purple);
        }
      }
    }
  }

  &-primary-inv {
    background-color: var(--cx-color-white);
    border-color: var(--cx-color-white);
    color: var(--cx-color-primary);

    &:hover,
    &.hover,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: var(--cx-color-light-purple);
      border-color: var(--cx-color-light-purple);
    }

    &:disabled,
    &.disabled {
      background-color: var(--cx-color-dark-purple);
      border-color: var(--cx-color-dark-purple);
      color: var(--cx-color-gray);
    }

    &:not(:disabled) {
      &:not(.disabled) {
        &:active,
        &.active {
          background-color: var(--cx-color-light-purple);
          border-color: var(--cx-color-light-purple);
        }
      }
    }
  }

  &-secondary {
    background-color: var(--cx-color-transparent);
    border-color: var(--cx-color-primary);
    border-width: 1px;
    color: var(--cx-color-primary);

    &:hover,
    &.hover,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: var(--cx-color-light-purple);
      border-color: var(--cx-color-primary);
      color: var(--cx-color-primary);
    }

    &:disabled,
    &.disabled {
      background-color: var(--cx-color-transparent);
      border-color: var(--cx-color-gray);
      color: var(--cx-color-gray);
    }

    &:not(:disabled) {
      &:not(.disabled) {
        &:active,
        &.active {
          background-color: var(--cx-color-light-purple);
          border-color: var(--cx-color-primary);
          color: var(--cx-color-primary);
        }
      }
    }
  }

  &-secondary-inv {
    background-color: var(--cx-color-transparent);
    border-color: var(--cx-color-white);
    color: var(--cx-color-white);

    &:hover,
    &.hover,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: var(--cx-color-dark-purple);
      color: var(--cx-color-white);
    }

    &:disabled,
    &.disabled {
      border-color: var(--cx-color-gray);
      color: var(--cx-color-gray);
    }

    &:not(:disabled) {
      &:not(.disabled) {
        &:active,
        &.active {
          background-color: var(--cx-color-dark-purple);
        }
      }
    }
  }

  &-secondary-orange {
    background-color: var(--cx-color-alarm-warning);
    border-color: var(--cx-color-alarm-warning);
    color: var(--cx-color-white);

    &:hover,
    &.hover,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: var(--cx-color-alarm-warning-dark);
      border-color: var(--cx-color-alarm-warning-dark);
      color: var(--cx-color-white);
    }

    &:disabled,
    &.disabled {
      background-color: var(--cx-color-rosa);
      border-color: var(--cx-color-rosa);
    }

    &:not(:disabled) {
      &:not(.disabled) {
        &:active,
        &.active {
          background-color: var(--cx-color-alarm-warning-dark);
          border-color: var(--cx-color-alarm-warning-dark);
          color: var(--cx-color-white);
        }
      }
    }
  }

  &-square {
    min-width: $valio-input-height-sm;
    width: $valio-input-height-sm;
  }

  &-sm {
    height: $valio-input-height-sm;
    line-height: $valio-input-height-sm;
    max-height: $valio-input-height-sm;
    min-height: $valio-input-height-sm;
    min-width: $valio-input-height-sm;
    padding: 0 24px;
  }

  &-lg {
    @include valio-link-bold-18();
    height: $valio-input-height-lg;
    line-height: $valio-input-height-lg;
    max-height: $valio-input-height-lg;
    min-height: $valio-input-height-lg;
    min-width: $valio-input-height-lg;
    padding: 0 32px;

    &.btn-square {
      min-width: $valio-input-height-lg;
      width: $valio-input-height-lg;
    }
  }

  &-tag {
    @include valio-copy-mini-14();
    @include valio-border-radius(50px);
    padding: 10px 16px;
    background-color: var(--cx-color-transparent);
    border-color: var(--cx-color-primary);
    color: var(--cx-color-text);

    &.btn-lg {
      height: $valio-input-height-lg;
      line-height: $valio-input-height-lg;
      max-height: $valio-input-height-lg;
      min-height: $valio-input-height-lg;
      min-width: $valio-input-height-lg;
      padding: 0 24px;
    }

    &:hover,
    &.hover,
    &:active,
    &.active,
    &:focus,
    &.focus,
    &.selected {
      background-color: var(--cx-color-light-purple);
    }


    &:disabled,
    &.disabled {
      border-color: var(--cx-color-gray);
      color: var(--cx-color-iris);
    }

    &:not(:disabled) {
      &:not(.disabled) {
        &:active,
        &.active {
          background-color: var(--cx-color-light-purple);
        }
      }
    }
  }

  &-tag-inv {
    @include valio-copy-mini-14();
    @include valio-border-radius(50px);
    padding: 10px 16px;
    background-color: var(--cx-color-transparent);
    border-color: var(--cx-color-white);
    color: var(--cx-color-white);

    &.btn-lg {
      height: $valio-input-height-lg;
      line-height: $valio-input-height-lg;
      max-height: $valio-input-height-lg;
      min-height: $valio-input-height-lg;
      min-width: $valio-input-height-lg;
      padding: 0 24px;
    }

    &:hover,
    &.hover,
    &:active,
    &.active,
    &:focus,
    &.focus,
    &.selected {
      background-color: var(--cx-color-dark-purple);
      color: var(--cx-color-white);
    }

    &:disabled,
    &.disabled {
      border-color: var(--cx-color-gray);
      color: var(--cx-color-gray);
    }

    &:not(:disabled) {
      &:not(.disabled) {
        &:active,
        &.active {
          background-color: var(--cx-color-dark-purple);
        }
      }
    }
  }

  &-close {
    background-color: var(--cx-color-alarm-discount);
    border-color: var(--cx-color-alarm-discount);
    color: var(--cx-color-white);

    &:hover,
    &.hover,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: var(--cx-color-alarm-warning);
      border-color: var(--cx-color-alarm-warning);
      color: var(--cx-color-white);
    }

    &:disabled,
    &.disabled {
      background-color: var(--cx-color-gray);
      border-color: var(--cx-color-gray);
    }

    &:not(:disabled) {
      &:not(.disabled) {
        &:active,
        &.active {
          background-color: var(--cx-color-alarm-warning);
          border-color: var(--cx-color-alarm-warning);
        }
      }
    }
  }

  &-link {
    @include valio-link();
    padding-left: 0;
    padding-right: 0;
  }

  &-link-inv {
    @include valio-link-inv();
    padding-left: 0;
    padding-right: 0;
  }

  &-centered {
    @include valio-flex-box();
    @include valio-justify-content(center);
    @include valio-align-items(center);
    padding-top: 2rem;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &-no-padding {
    padding: 0;
    text-align: center;
  }

  &-icon {
    position: relative;
    padding: 0;

    .valio-icon {
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &-icon-link {
    position: relative;
    padding-right: 32px;

    .valio-icon {
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }

  &-icon-tag {
    @include valio-bold-link-mini-14();
    position: relative;
    padding-left: 40px;

    .valio-icon {
      margin: auto;
      position: absolute;
      top: 8px;
      bottom: 12px;
      left: 8px;
    }
  }

  &-calendar {
    @include valio-border-radius(0 $valio-btn-border-radius $valio-btn-border-radius 0);
    border-color: var(--cx-color-dark);
    border-left: none;
    color: var(--cx-color-text);

  }

  &-block-md-down {
    @include media-breakpoint-down(md) {
      display: block;
      width: 100%;
      margin-bottom: 8px;
    }
  }

  &-block-sm-down {
    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
      margin-bottom: 8px;
    }
  }
}

// VALO Link styles
a {
  &:not(.btn) {
    @include valio-link();
  }

  @include media-breakpoint-down(sm) {
    min-height: unset;
    min-width: unset;
    display: inline;
  }
}

.btn-group, .btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
