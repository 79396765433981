valio-cx-navigation-ui {
  @include valio-flex-box();

  @extend %nav-heading !optional;
  @extend %nav-icon !optional;
  @extend %nav-wrapper !optional;
  @extend %nav-childs !optional;
  @extend %nav-links !optional;
  @extend %nav-back-button !optional;
  @extend %nav-wrap !optional;

  nav {
    outline: none;
  }

  &.flyout {
    @include media-breakpoint-down(md) {
      @include valio-flex-direction-column();
      color: var(--cx-color-black);
    }

    &.is-open {
      .wrapper {
        @include showWrapperDesktop();
        min-width: 344px;

        @include media-breakpoint-down(sm) {
          width: 100%;
          min-width: auto;
        }
      }

      > nav {
        @include media-breakpoint-down(md) {
          display: none;
        }

        > h5 {
          .valio-icon {
            &:not(.icon-account) {
              transform: rotate(180deg);
            }
          }
        }
      }

      nav {
        &.is-open {
          @include media-breakpoint-down(md) {
            display: initial;
          }
        }
      }

      nav {
        &.is-opened {
          @include media-breakpoint-down(md) {
            display: initial;
          }

          > .wrapper {
            > .childs {
              > nav {
                @include media-breakpoint-down(md) {
                  display: none;
                }

                &.is-open {
                  @include media-breakpoint-down(md) {
                    display: initial;

                    cx-generic-link {
                      &.title-open {
                        background-color: var(--cx-color-peach);
                        margin: -32px 0 0;
                        padding: 24px 0 16px;

                        a {
                          @include valio-tiny-12();
                          padding: 0;
                        }

                        .icon-submenu {
                          position: relative;
                          display: inline-block;
                          width: 32px;
                          height: 16px;
                          vertical-align: middle;

                          .valio-icon {
                            display: inline-block;
                            left: 0;
                            top: 0;
                          }
                        }

                        .valio-icon {
                          display: none;
                        }
                      }
                    }

                    .childs-submenu {
                      padding: 0;

                      cx-generic-link {
                        padding: 16px 24px;
                        margin: 0;
                        border-bottom: 1px solid var(--cx-color-line);
                        background: var(--cx-color-white);

                        a {
                          padding: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          > .wrapper {
            @include media-breakpoint-down(md) {
              height: auto;
            }
          }
        }
      }
    }

    // Show dropdowns via hover when no nav is focused
    > nav {
      &:last-child {
        @include media-breakpoint-up(lg) {
          overflow: hidden;
        }
      }

      > h5 {
        @include valio-link();
        @include valio-copy-small-16();
        border-bottom: none;
        margin-top: 0;
        padding: 0 24px 0 0;
        height: $valio-input-height-lg;

        @include media-breakpoint-up(lg) {
          padding: 0 48px 0 0;
        }

        .my-account-name {
          display: inline-block;
          max-width: none;
          vertical-align: middle;

          @include media-breakpoint-up(md) {
            max-width: 408px;
          }

          @include media-breakpoint-up(lg) {
            max-width: 144px;
          }

          @include media-breakpoint-up(xl) {
            max-width: 352px;
          }

          @media (min-width: $valio-page-md-max-width) {
            max-width: none;
          }

          + .valio-icon {
            &.icon-inline {
              vertical-align: top;
            }
          }

          .code,
          .name {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .name {
            color: var(--cx-color-gray);
          }
        }
      }

      > .wrapper {
        @include hideWrapperDesktop();
        border: none;
        box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.1);
        z-index: 200;

        @include media-breakpoint-down(lg) {
          position: absolute;
          right: 0;
          background-color: var(--cx-color-white);
          height: auto;
          padding: 0;
        }

        @include media-breakpoint-up(lg) {
          background: var(--cx-color-white);
        }

        @include media-breakpoint-up(md) {
          transition: opacity 0.4s;
          padding: 0;
        }

        .childs {
          cx-generic-link {
            @include media-breakpoint-down(md) {
              position: relative;
            }

            .valio-icon {
              @include media-breakpoint-down(md) {
                position: absolute;
                right: 24px;
                top: 8px;
              }
            }
          }
        }
      }
    }

    span {
      @include media-breakpoint-down(md) {
        padding: 0;
        border-bottom: 0;
      }
      @include media-breakpoint-down(sm) {
        padding: 1rem;
      }
    }

    .childs {
      background-color: var(--cx-color-cloud);
      min-width: 250px;
      padding-top: 24px;
      padding-bottom: 24px;
      display: block;

      .icon-submenu {
        display: none;
        margin-left: 24px;

        svg {
          width: 10px;
        }
      }

      @include media-breakpoint-down(xs) {
        width: auto;
      }

      &.valio-units {
        background-color: var(--cx-color-white);
        border-bottom: 1px solid var(--cx-color-line);
        padding-top: 24px;
        padding-bottom: 24px;

        .valio-unit {
          display: block;
          margin: 0 24px;
          position: relative;

          &:not(:last-child) {
            .valio-unit-name {
              padding-bottom: 16px;
              border-bottom: 1px solid var(--cx-color-line);
              margin-bottom: 16px;
            }
          }

          &:hover {
            cursor: pointer;
          }

          &:hover,
          &.selected {
            &:before {
              content: ' ';
              position: absolute;
              left: -24px;
              top: -5px;
              display: block;
              height: 26px;
              width: 4px;
              background-color: var(--cx-color-primary);
            }
          }

          &.selected {
            .valio-unit-name {
              @include valio-bold-link-mini-14();
            }
          }

          .valio-unit-name {
            @include valio-copy-mini-14();
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 330px;
            white-space: nowrap;

            @include media-breakpoint-down(sm) {
              max-width: none;
            }
          }
        }
      }

      nav {
        &:last-child {
          cx-generic-link {
            margin-bottom: 0;
          }
        }

        cx-generic-link {
          margin: 0;
          border-bottom: none;

          a {
            @include valio-copy-small-16();
            padding: 8px 24px 16px;
          }
        }
      }
    }
  }
}

valio-main-navigation-component {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  display: block;
  z-index: 100;
  height: 56px;
  background-color: var(--cx-color-main-navigation-background);

  // IE 10 and above
  @media screen and (-ms-high-contrast: active) {
    position: fixed !important;
    width: inherit;
    left: 0;
    top: inherit !important;
  }

  .header-info-slot {
    display: inline-block;
    width: 230px;
    height: 56px;
    padding: 8px 24px;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
      padding: 10px 16px;
    }

    &:hover,
    &.hover,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: var(--cx-color-dark-purple);
      border-color: var(--cx-color-dark-purple);
    }

    .header-block {
      @include media-breakpoint-down(md) {
        display: block;
      }

      .cx-date {
        padding: 0;
      }
    }

    .valio-icon {
      float: right;
      line-height: $valio-input-height-sm;
      height: $valio-input-height-sm;
    }
  }

  .main-navigation {
    @include media-breakpoint-up(md) {
      white-space: nowrap;
    }

    .main-navigation-slot {
      display: none;
      height: 56px;
      vertical-align: top;
      padding-left: 16px;

      @include media-breakpoint-up(md) {
        display: inline-block;
      }

      valio-cx-navigation-ui {
        display: inline-block;

        &.flyout {
          > nav {
            @include media-breakpoint-up(md) {
              padding-left: 8px;
              display: block;
            }

            @include media-breakpoint-up(xl) {
              padding-left: 32px;
            }

            .main-navigation-wrapper {
              @include valio-flex-box();
              border: none;
            }
          }
        }

        > nav {
          &:hover {
            .main-navigation-wrapper {
              border: none;
            }
          }

          h5 {
            padding: 0;
            margin: 0;

            .valio-icon {
              @include media-breakpoint-up(md) {
                padding: 0 0 0 48px;
              }
            }
          }
        }

        cx-generic-link {
          display: inline-block;
          margin: 20px 0;
          text-transform: none;
          border-bottom: none;

          a {
            @include valio-copy-mini-14();
            pointer-events: none;
            padding: 0;
          }
        }
      }
    }
  }
}

.main-navigation-wrapper {
  position: absolute;
  top: 56px;
  left: 10px;
  right: 0;
  border: none;
  box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.1);

  .main-categories,
  .child-categories {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 40px 16px 40px 40px;

    @include media-breakpoint-up(lg) {
      padding: 40px;
    }

    ul {
      li {
        @include valio-link();
        margin-bottom: 16px;
      }
    }
  }

  .main-categories {
    background-color: var(--cx-color-cloud);
    max-width: 360px;
  }

  .child-categories {
    li {
      a {
        white-space: normal;
      }
    }
  }
}

valio-public-product-navigation-component,
valio-public-vendor-navigation-component {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
    height: 38px;
    padding: 0 16px 0 0;
  }

  @include media-breakpoint-up(xl) {
    padding: 0 40px 0 0;
  }

  valio-cx-navigation-ui {
    display: inline-block;

    &.flyout {
      > nav {
        .main-navigation-wrapper {
          border: none;
        }

        > h5 {
          @include media-breakpoint-up(lg) {
            padding: 0;
            margin: 8px 8px 32px 8px;
            height: 24px;
          }

          cx-generic-link {
            @include valio-copy-small-16();
            display: inline-block;

            a {
              pointer-events: none;
            }
          }
        }
      }
    }

    > nav {
      &:hover {
        .main-navigation-wrapper {
          border: none;
        }
      }
    }

    .main-navigation-wrapper {
      top: 86px;
    }
  }
}

valio-mobile-navigation-component {
  .head-categories,
  .main-categories,
  .child-categories {
    width: 100%;

    ul {
      li {
        position: relative;
        padding: 16px 0 16px 24px;
        border-bottom: 1px solid var(--cx-color-line);

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .main-categories {
    &.active {
      display: none;
    }
  }

  .main-categories,
  .child-categories {
    .selected-main-category {
      @include valio-bold-link-mini-14();
      background-color: var(--cx-color-light-peach);
      text-align: center;

      .valio-icon {
        position: absolute;
        left: 24px;
      }

      a {
        z-index: 10;
      }
    }
  }
}

.head-categories,
.main-categories,
.child-categories {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      @include valio-copy-mini-14();

      @include media-breakpoint-up(lg) {
        @include valio-copy-small-16();
      }

      &.active {
        @include valio-copy-mini-14();

        @include media-breakpoint-up(lg) {
          @include valio-link-bold-small-16();
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.head-categories,
.main-categories {
  display: none;

  &.visible {
    display: block;
  }

  ul {
    position: relative;

    li {
      &:not(.selected-main-category) {
        @include valio-flex-box();
        @include valio-justify-content(space-between);
        @include valio-align-items(center);

        .valio-icon {
          position: absolute;
          right: 24px;

          @include media-breakpoint-up(lg) {
            right: 0;
          }
        }

        &.active {
          .valio-icon {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    > nav {
      > .wrapper {
        @include hideWrapperDesktop();
      }

      /* we only treat the first wrapper as a collapsible container */
      &:hover {
        > .wrapper {
          @include showWrapperDesktop();
        }
      }
    }

    // Show dropdowns via focus only once a nav is clicked
    &:focus-within {
      > nav {
        &:focus {
          > .wrapper {
            @include showWrapperDesktop();
          }
        }
      }
    }
  }
}

.child-categories {
  background-color: var(--cx-color-white);
}
