valio-cx-banner {
  @include valio-content-margins();

  & {
    background-color: var(--cx-color-white);
  }

  .banner-paddings {
    &.is-anonymous {
      padding: 0 20px 20px 20px;

      @include media-breakpoint-down(md) {
        padding: 0 16px 16px 16px;
      }
    }

    .banner-container {
      position: relative;
      border: 1px solid var(--cx-color-transparent);
      margin: -1px;

      @include media-breakpoint-up(lg) {
        min-height: 452px;
      }
      @include media-breakpoint-down(md) {
        min-height: 340px;
      }

      cx-media {
        position: absolute;
        height: 100%;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .banner-content {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        margin: 48px 24px;
        word-break: break-word;
        white-space: normal;

        @include media-breakpoint-up(lg) {
          margin: 48px 56px;
          max-width: 480px;
        }

        @include media-breakpoint-up(xl) {
          margin: 80px 56px;
        }

        &.text-background-box {
          background-color: var(--cx-color-white);
          width: auto;
          padding: 48px 32px 8px;
          margin: 184px 16px 0;

          @include media-breakpoint-up(md) {
            min-height: 322px;
            width: 45%;
            padding: 48px 32px 8px;
            margin: 16px 0 0 16px;
          }

          @include media-breakpoint-up(lg) {
            width: 50%;
            padding: 56px 64px 8px;
            margin: 80px 64px 0;
          }
        }

        &.text-shadow {
          .h1,
          p {
            text-shadow: var(--cx-color-iris) 1px 1px 0;
          }
        }

        &.text-white {
          color: var(--cx-color-white);

          a:not(.btn) {
            color: var(--cx-color-white);
          }

          &.text-shadow {
            .h1,
            p {
              text-shadow: var(--cx-color-dark) 1px 1px 0;
            }
          }
        }

        .h1 {
          margin-bottom: 32px;
        }

        p {
          @include valio-copy-18();
        }

        b {
          @include valio-link-bold-18();
        }
      }
    }
  }
}

.PartnerBannerTopSlot {
  margin-top: -16px;
  margin-bottom: 16px;

  valio-cx-banner {
    margin: 0;
    background: none;

    &.se-storefront-component {
      &:first-child {
        margin-top: 48px;
      }

      &:before {
        content: attr(data-smartedit-component-id);
      }
    }

    .banner-paddings {
      padding: 0;

      .banner-container {
        min-height: auto;

        cx-media {
          position: relative;
        }

        .banner-content {
          position: absolute;
        }
      }
    }
  }
}

valio-cx-product-carousel {
  valio-cx-banner {
    display: inline-block;
    margin: 0;
    background: none;
    vertical-align: top;

    .banner-paddings {
      &,
      &.is-anonymous {
        padding: 0 8px 16px;
      }

      .banner-container {
        height: 340px;
        min-height: 340px;

        cx-media {
          &.dark-layer {
            &:after {
              background: var(--cx-color-black);
              opacity: .3;
              content: "";
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 100%;
            }
          }

          img {
            @include valio-border-radius($valio-btn-border-radius);
          }
        }

        .banner-content {
          margin: 68px 34px 0;

          @include media-breakpoint-up(lg) {
            margin: 68px 34px 0;
          }

          @include media-breakpoint-up(xl) {
            margin: 68px 34px 0;
          }

          .h1 {
            @include valio-H3-24();
            margin-bottom: 24px;
          }
        }
      }
    }
  }
}

valio-cx-three-box-banner {
  .banner-container {
    position: relative;
    border: 1px solid var(--cx-color-transparent);
    margin: -1px;

    cx-media {
      position: absolute;
      height: 100%;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .banner-content {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 48px 56px;
      text-align: center;

      @include media-breakpoint-up(md) {
        margin: 48px 16px;
      }

      @include media-breakpoint-up(lg) {
        margin: 80px 56px;
      }

      @include media-breakpoint-up(xl) {
        margin: 80px 256px;
      }

      &.text-white {
        color: var(--cx-color-white);
      }

      .h1 {
        margin-bottom: 48px;
        text-shadow: var(--cx-color-iris) 2px 1px 0;
      }

      .banner-boxes {
        @include media-breakpoint-up(md) {
          margin-left: -4px;
          margin-right: -4px;
        }

        @include media-breakpoint-up(lg) {
          margin-left: -20px;
          margin-right: -20px;
          margin-bottom: 3rem;
        }

        > [class*='col-'] {
          margin-bottom: 1rem;

          @include media-breakpoint-up(md) {
            padding-right: 4px;
            padding-left: 4px;
          }

          @include media-breakpoint-up(lg) {
            padding-right: 20px;
            padding-left: 20px;
          }
        }
      }

      .banner-box {
        @include valio-border-radius($valio-lg-border-radius);
        background: var(--cx-color-light-peach);
        color: var(--cx-color-text);
        opacity: 0.8;
        padding: 1rem;

        @include media-breakpoint-up(lg) {
          padding: 2rem;
        }

        p {
          @include valio-copy-18();
          margin: 0;

          b {
            @include valio-link-bold-18();
          }
        }
      }
    }
  }
}
