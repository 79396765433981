valio-cx-login-form,
valio-cx-forgot-password,
valio-cx-reset-password {
  @include valio-copy-small-16();
  text-align: center;
  margin: auto;

  .login-form {
    @include valio-border-radius($valio-lg-border-radius);
    background-color: var(--cx-color-white);
    box-shadow: 7px 4px 64px rgba(45, 21, 115, 0.1);
    padding: 32px 16px 40px 16px;

    @include media-breakpoint-up(sm) {
      padding: 56px 168px 80px 168px;
    }

    @include media-breakpoint-up(lg) {
      padding: 72px 248px;
    }
  }

  .content-group {
    @include media-breakpoint-up(lg) {
      padding-bottom: 32px;
    }

    &:last-child {
      @include media-breakpoint-up(lg) {
        padding-bottom: 0;
      }

      p {
        margin-bottom: 0;
      }
    }

    h1 {
      margin-bottom: 1rem;
    }

    .form-check {
      margin: 0 0 16px 0;
    }
  }
}
