.ContentPageBlueTemplate {
  max-width: $valio-page-md-max-width;
  margin: auto auto 64px;
  padding: 0 16px;

  @include media-breakpoint-up(md) {
    padding: 0 48px;
  }

  cx-page-slot {
    &.Section2A {
      @include valio-copy-18();

      @include media-breakpoint-up(lg) {
        padding: 0 16rem;
      }

      cx-paragraph {
        text-align: center;
      }
    }

    &.Section2B {
      cx-paragraph {
        padding-left: 2rem;
        padding-top: 3rem;
      }
    }
  }
}
