.import-from-file {
  @include valio-scrollbar();

  .text-area-scroll {
    @include valio-border-radius($valio-btn-border-radius);
    border: 1px solid var(--cx-color-light-purple);
    display: inline-block;
    max-height: 300px;
    overflow: auto;
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      max-height: 500px;
    }
  }

  .text-area-wrapper {
    display: inline-block;
    max-width: 296px;
    position: relative;

    @include media-breakpoint-up(md) {
      max-width: 320px;
    }

    .line-numbers {
      @include valio-border-radius($valio-btn-border-radius);
      display: inline-block;
      background: var(--cx-color-pink);
      margin: 0;
      padding: 8px;
      vertical-align: top;
      width: 40px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }

    .text-area {
      display: inline-block;
      margin: 0;
      vertical-align: top;
      padding-left: 40px;
      width: 100%;

      textarea {
        display: block;
        border: none;
        padding: 8px 16px;
        resize: none;
        width: 100%;
        white-space: nowrap;
      }
    }
  }

  .error-description {
    @include valio-link-bold-small-16();
    color: var(--cx-color-danger);
  }

  .footer-panel {
    margin-top: 24px;
    border-top: 1px solid var(--cx-color-iris);
    padding-top: 24px;
  }
}
