.PartnersPageTemplate {
  @include valio-single-column-layout();
  margin: auto auto 64px;

  cx-page-slot {
    max-width: $valio-page-md-max-width;
    margin: auto;

    &.Section1,
    &.Section2A,
    &.Section2B,
    &.Section3 {
      @include valio-flex-1();
      width: 100%;
      padding: 0 16px;

      > * {
        flex: none;
      }

      cx-paragraph {
        width: 100%;
      }
    }
  }

  .page-header {
    @include valio-page-header();
    & {
      text-align: center;
    }

    @include media-breakpoint-up(md) {
      padding: 64px 32px;
    }
  }
}
