.valio-product-summary {
  @include valio-flex-box();
  @include valio-flex-direction-column();
  flex-grow: 0;
  grid-column: 1;
  grid-row: 1/3;
  width: 100%;

  @include media-breakpoint-up(md) {
    padding: 40px 16px 0;
    min-height: 300px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 550px;
  }

  valio-cx-product-info {
    max-width: 600px;

    @include media-breakpoint-down(md) {
      max-width: unset;
    }

    .image-center {
      @include media-breakpoint-down(sm) {
        margin-bottom: 2rem;
      }

      .cx-product-image {
        @include media-breakpoint-up(md) {
          position: absolute;
          left: 70%;
          right: 0;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        @include media-breakpoint-down(sm) {
          background: linear-gradient(90deg, var(--cx-color-pink) 50%, var(--cx-color-blueberry) 50%);
          margin: 0 -16px 0 -16px;
          padding: 3rem;
        }

        &.is-missing {
          display: none;
        }

        img {
          @include valio-border-radius($valio-lg-border-radius);
          display: block;
          margin: auto;
          max-height: 420px;
          max-width: 420px;

          @include media-breakpoint-down(md) {
            max-height: 320px;
            max-width: 320px;
          }

          @include media-breakpoint-down(sm) {
            max-width: 100%;
            max-height: 220px;
          }
        }
      }
    }

    .product-top {
      .valio-product-bread-crumb {
        .category-bread-crumb {
          display: inline-block;

          &:last-child {
            display: none;

            span {
              display: none;
            }

            a {
              font-weight: normal;
              pointer-events: none;
            }
          }

          &:nth-last-child(2) {
            span {
              display: none;
            }
          }

          span,
          a {
            @include valio-tiny-12();
            font-weight: bold;
            color: var(--cx-color-text);
          }
        }
      }

      .valio-icon {
        position: absolute;
        right: 2rem;
      }
    }

    .product-info {
      @include valio-copy-mini-14();
      padding: 32px 0;

      @include media-breakpoint-down(md) {
        padding-bottom: 0;
        max-width: 50%;
      }

      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }

      .product-icon {
        display: inline-block;
        margin-right: 8px;
      }

      .product-info-section {
        padding: 2rem 0;

        @include media-breakpoint-down(md) {
          @include valio-tiny-12();
        }

        span {
          padding-right: 0;

          + span {
            &:before {
              content: "|";
              margin: 0 16px;
            }
          }
        }
      }

      .product-price-section {
        @include valio-copy-small-16();

        strong {
          @include valio-link-bold-small-16();
        }
      }

      h1 {
        padding-top: 1rem;
      }

      span {
        padding-right: 16px;
      }
    }
  }

  valio-cx-product-price {
    max-width: none;

    .price {
      @include valio-mobile-full-euros-32();
      color: var(--cx-color-text);
      display: inline-block;
      margin: 0 0 40px 0;

      @include media-breakpoint-up(md) {
        padding: 24px 0 24px 8px;
        margin: 0;
      }

      @include media-breakpoint-up(lg) {
        @include valio-H1-38();
      }

      .supsub {
        sub {
          @include valio-copy-small-16();
        }
      }
    }

    .campaign-price {
      margin-right: 24px;
    }

    @include media-breakpoint-down(sm) {
      span {
        display: inherit;
        text-align: right;
      }
    }
  }

  valio-cx-add-to-cart {
    @include valio-flex-box();
    grid-column: 1;

    @include media-breakpoint-up(md) {
      max-width: 600px;
    }

    @include media-breakpoint-down(sm) {
      display: unset;
    }

    .quantity {
      label {
        margin: 15px 0 10px 0;
      }

      .info {
        margin: 0 15px 0 15px;
        color: var(--cx-color-secondary);
      }
    }

    .btn-product-cart {
      @include media-breakpoint-down(sm) {
        margin-top: 1rem;
        margin-left: 0;
        width: 100%;
      }
    }
  }

  .product-delivery-period {
    @include valio-link-bold-small-16();
    @include valio-border-radius($valio-btn-border-radius);
    background: var(--cx-color-secondary);
    display: inline-block;
    padding: 8px 24px;
    margin-top: 16px;

    @include media-breakpoint-down(md) {
      @include valio-bold-link-mini-14();
    }

    @include media-breakpoint-down(sm) {
      display: block;
      text-align: center;
    }
  }

  .product-description {
    @include valio-copy-small-16();
    max-width: 55%;
    padding-top: 60px;

    @include media-breakpoint-down(lg) {
      max-width: 60%;
    }
  }
}
