// wrapped in body in top-most index.scss
&.has-searchbox-results {
  valio-suggestive-search,
  valio-baseorder-search,
  valio-cx-product-facet-navigation {
    .suggestive-row {
      .results {
        display: block;
      }
    }
  }
}

valio-suggestive-search,
valio-baseorder-search,
valio-cx-product-facet-navigation {
  .suggestive-row {
    @include valio-copy-mini-14();

    .searchbox {
      position: relative;

      .valio-icon {
        position: absolute;
        left: 16px;
        top: 16px;
      }

      input {
        @include valio-border-radius($valio-btn-border-radius);
        height: $valio-input-height-lg;
        width: 100%;
        padding: 0 16px 0 56px;
        background: var(--cx-color-transparent);
        border: 1px solid var(--cx-color-dark);

        &:focus {
          outline: none;
        }
      }

      .is-invalid {
        border: 1px solid var(--cx-color-red) !important;
      }
    }

    .ng-select {
      .ng-select-container {
        @include valio-copy-mini-14();
        background: var(--cx-color-transparent);
        border-color: var(--cx-color-dark);
      }

      &.ng-select-single {
        .ng-select-container {
          height: $valio-input-height-lg;

          .ng-value-container {
            .ng-input {
              top: 0;
            }
          }
        }
      }

      .ng-dropdown-panel {
        width: auto;
        min-width: 100%;
      }
    }

    valio-suggestive-cx-searchbox {
      @include valio-scrollbar();
      display: inline-block;
      width: 100%;

      .results {
        display: none;
        position: absolute;
        width: 90vw;
        max-height: 400px;
        z-index: 80;
        background-color: var(--cx-color-white);
        box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.1);
        overflow: auto;
        white-space: nowrap;
        padding: 8px 0;

        @include media-breakpoint-up(md) {
          width: auto;
          left: -50%;
        }

        @include media-breakpoint-up(lg) {
          left: auto;
        }

        @include media-breakpoint-down(sm) {
          width: 100%;
          left: 0;
        }

        .search-result-anchor {
          display: block;

          &:hover {
            text-decoration: none;

            .product {
              @include valio-bold-link-mini-14();
              background-color: var(--cx-color-pink);
            }
          }

          .product {
            @include valio-copy-mini-14();
            display: block;
            padding: 8px 16px;

            .code {
              color: var(--cx-color-gray);
              display: inline-block;
              width: 80px;
            }
          }
        }
      }

      .searchbox {
        width: 100%;

        .reset {
          position: absolute;
          right: 55px;
          top: 16px;
        }
      }
    }

    .suggestive-row-unit {
      display: inline-block;
      line-height: 26px;
      left: -50px;
      white-space: nowrap;
    }
  }
}

valio-cx-cart-details {
  min-width: 0;

  .CartCarouselSlot {
    valio-cx-product-carousel {
      display: block;
      margin: 0;

      .cx-product-container {
        @include media-breakpoint-down(sm) {
          padding: 0 16px;
        }

        valio-last-purchases,
        valio-top-sellers,
        valio-bought-together {
          valio-cx-carousel {
            padding: 0;

            .product-container-header {
              @include valio-H3-24();
              margin: 80px 0 32px 0;

              @include media-breakpoint-down(md) {
                @include valio-link-bold-18();
              }
            }

            .carousel-panel {
              .carousel-scroll {
                &:not(.show-scroll) {
                  padding: 0;
                  margin: 0 -8px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.valio-cart-sticky-total-bar-rows {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  display: block;
  z-index: 50;
  height: 102px;
  width: 100%;
  color: var(--cx-color-purple);
  background-color: var(--cx-color-white);
  margin: -33px 0 -24px;
  padding: 24px 0;
  border-top: 1px solid var(--cx-color-iris);

  @include media-breakpoint-down(sm) {
    height: 163px;
  }

  p {
    b {
      @include valio-link-bold-small-16();
    }
  }

  // IE 10 and above
  @media screen and (-ms-high-contrast: active) {
    position: fixed !important;
    width: inherit;
    bottom: 0;
  }

  .cart-entry-totals {
    margin-right: 0;

    .top-border-slot {
      border-top: 3px solid var(--cx-color-purple);
      padding-top: 24px;
      padding-bottom: 24px;

      .total {
        @include valio-link-bold-18();
        margin-right: 8px;
      }

      .alv {
        @include valio-copy-small-16();
      }

      .total-shipping {
        @include valio-copy-mini-14();
      }

      @include valio-supsub();

      .supsub {
        sup {
          @include valio-link-bold-small-16();
          top: 4px;
        }

        sub {
          @include valio-bold-link-mini-14();
          top: 0;
        }
      }
    }
  }
}
