valio-cx-my-account-header {
  @include valio-page-header();

  &{
    padding: 24px;
  }

  @include media-breakpoint-up(md) {
    padding: 32px 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 56px 0 40px;
  }
}

valio-cx-sidebar {
  @include valio-side-panel-content();

  @include media-breakpoint-down(sm) {
    padding: 0;
  }

  .close-menu {
    position: absolute;
    top: 5px;
    right: 5px;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .my-account-menu {
    @include valio-my-account-menu();

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &.is-open {
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: var(--cx-color-white);
      padding: 40px 16px;
    }
  }
}

valio-cx-update-password,
valio-cx-update-profile,
valio-cx-contracts-profile,
valio-cx-close-account-profile {
  background-color: var(--cx-color-white);
  box-shadow: 0 0 44px rgba(0, 0, 0, 0.09);

  h3 {
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }
  }

  p {
    margin-bottom: 8px;

    &.user-name {
      margin-bottom: 32px;
    }
  }

  form {
    label {
      display: block;
    }
  }
}

.container-account {
  padding: 24px 32px 40px;

  @include media-breakpoint-up(md) {
    padding: 48px 32px 40px;
  }

  @include media-breakpoint-up(lg) {
    padding: 64px 88px;
    max-width: 600px;
    margin: auto;
  }
}

.contracts {
  background-color: var(--cx-color-white);

  .value {
    @include valio-bold-link-mini-14();

    &.customer-code {
      @include media-breakpoint-up(lg) {
        text-align: right;
      }
    }
  }

  .header {
    text-align: center;
    padding: 24px 40px 32px;

    @include media-breakpoint-up(md) {
      padding: 48px 64px 32px;
    }

    @include media-breakpoint-up(lg) {
      padding: 64px 120px 32px;
    }
  }

  .company {
    @include valio-copy-mini-14();
    background-color: var(--cx-color-pink);

    .description {
      @include valio-link();
      @include valio-link-bold-18();
      @include valio-flex-box();
      @include valio-flex-direction-row();
      align-items: center;
      padding: 16px 32px;

      @include media-breakpoint-down(sm) {
        padding: 16px;
      }
    }

    .partners {
      padding: 24px 32px 40px;
      background-color: var(--cx-color-white);

      @include media-breakpoint-down(sm) {
        padding: 24px 16px 40px;
      }

      .partner {
        padding: 24px 0;
        border-bottom: 1px solid var(--cx-color-iris);

        .customer {
          @include media-breakpoint-down(xs) {
            padding-bottom: 8px;
          }
        }
      }

      &.collapsed {
        padding: 4px;

        .partner {
          display: none;
        }
      }
    }
  }
}
