valio-frontpage-component {
  width: 100%;
}

valio-cx-product-facet-navigation {
  @include media-breakpoint-down(xs) {
    width: 100%;
  }

  .product-facet-navigation {
    .search-header,
    .search-box,
    .spelling-suggestions,
    .valio-facets {
      padding-right: $valio-container-padding-sm;
      padding-left: $valio-container-padding-sm;

      @include media-breakpoint-up(md) {
        padding-right: $valio-container-padding;
        padding-left: $valio-container-padding;
      }
    }

    .PartnerBannerTopSlot {
      margin-right: -$valio-container-padding-sm;
      margin-left: -$valio-container-padding-sm;

      @include media-breakpoint-up(md) {
        margin-right: -$valio-container-padding;
        margin-left: -$valio-container-padding;
      }
    }

    &.with-calendar {
      .search-header,
      .search-box,
      .spelling-suggestions,
      .valio-facets {
        padding-right: $valio-container-padding-sm;
        padding-left: $valio-container-padding-sm;

        @include media-breakpoint-up(md) {
          padding-right: $valio-container-padding;
          padding-left: $valio-container-padding;
        }
      }

      .PartnerBannerTopSlot {
        margin-right: -$valio-container-padding-sm;
        margin-left: -$valio-container-padding-sm;

        @include media-breakpoint-up(md) {
          margin-right: -$valio-container-padding;
          margin-left: -$valio-container-padding;
        }
      }
    }
  }

  .search-header {
    position: relative;
    background-color: var(--cx-color-light-purple);
    padding-top: 16px;
    padding-bottom: 16px;

    valio-cx-product-partner {
      .partner-name {
        @include valio-tiny-12();
        font-weight: bold;
        margin-bottom: 24px;
      }

      .partner-unactive {
        @include valio-flex-box();
        @include valio-justify-content(space-between);
        @include valio-align-items(center);

        .partner-no-contract-text {
          @include valio-flex-1();
        }
      }

      .partner-contract {
        @include valio-copy-18();
        margin-top: 24px;
        margin-bottom: 24px;
      }
    }

    .facet-nav {
      margin-bottom: 48px;

      @include media-breakpoint-down(md) {
        margin-bottom: 24px;
      }

      &:empty {
        display: none;
      }

      .category-bread-crumb {
        display: inline-block;

        &:last-child {
          span {
            display: none;
          }

          a {
            font-weight: normal;
          }
        }

        &:first-child {
          span {
            display: inline;
          }

          a {
            font-weight: bold;
          }
        }

        span,
        a {
          @include valio-tiny-12();
          font-weight: bold;
          color: var(--cx-color-text);
        }
      }
    }

    .facet-nav-mobile {
      display: none;

      @include media-breakpoint-down(md) {
        display: block;
        margin-bottom: 16px;
      }

      .category-bread-crumb {
        span,
        a {
          @include valio-link-bold-18();
          color: var(--cx-color-text);
          margin-right: 16px;
        }
      }
    }

    .facet-frontpage-header,
    .facet-header {
      @include valio-H3-24();
      margin-bottom: 16px;

      @include media-breakpoint-down(md) {
        margin-top: 24px;
      }
    }

    .facet-frontpage-contract {
      @include valio-copy-small-16();
      margin-bottom: 24px;
    }

    .cx-search-facet {
      @include media-breakpoint-down(md) {
        @include valio-scrollbar();
        height: 48px;
      }

      .cx-facet-group {
        .cx-facet-list {
          padding: 0;
          list-style: none;
          margin: 0;

          @include media-breakpoint-down(md) {
            position: absolute;
            left: 0;
            right: 0;
            padding: 0 8px 0 48px;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
          }

          @include media-breakpoint-down(sm) {
            padding: 0 8px 0 16px;
          }

          @include media-breakpoint-up(lg) {
            position: relative;
          }

          &.toggleCategoryList {
            @include media-breakpoint-up(lg) {
              overflow: hidden;
              height: 48px;
            }

            &.show-toggle {
              @include media-breakpoint-up(lg) {
                padding-right: 112px;
              }
            }

            &:empty {
              display: none;
            }

            li {
              &.limitedCategories {
                + .toggleMoreCategories {
                  position: absolute;
                  top: 0;
                  right: 0;
                  background-color: var(--cx-color-light-purple);
                  margin-right: 0;

                  @include media-breakpoint-down(md) {
                    display: none;
                  }

                  span {
                    @include valio-link();
                  }

                  .showLess {
                    display: none;
                  }

                  .showMore {
                    display: inline-block;
                  }
                }
              }
            }
          }

          li {
            @include valio-link();
            margin: 0 8px 8px 0;

            &.limitedCategoriesToggle {
              font-weight: bold;

              .showLess {
                display: inline-block;
              }

              .showMore {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .search-box {
    padding-top: 24px;
    padding-bottom: 16px;
  }

  .spelling-suggestions {
    padding-top: 24px;
    padding-bottom: 16px;
  }

  .valio-facets {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      margin-top: 16px;
    }

    valio-product-facet-list,
    valio-cx-product-contractual-switch {
      display: inline-block;
      vertical-align: top;
    }

    .cx-search-facet {
      @include media-breakpoint-down(sm) {
        display: none;
      }

      .cx-facet-group {
        display: inline-block;
        margin: 0 8px 8px 0;

        .facet-name {
          @include valio-link();
          @include valio-copy-mini-14();
          @include valio-border-radius($valio-btn-border-radius);
          padding: 5px 16px;
          border: 1px solid var(--cx-color-gray);
          box-shadow: 0 4px 24px rgba(220, 216, 254, 0.1);
          cursor: pointer;

          .valio-icon {
            margin-left: 16px;
            pointer-events: none;
          }

          &.has-active-facet {
            @include valio-bold-link-mini-14();
            border: 1px solid var(--cx-color-text);
          }
        }

        .cx-facet-list {
          @include valio-border-radius($valio-btn-border-radius);
          display: none;
          position: absolute;
          padding: 16px;
          margin-top: 8px;
          box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.07);
          background-color: var(--cx-color-white);
          z-index: 40;

          li {
            @include valio-link();
            @include valio-copy-mini-14();
            @include valio-border-radius($valio-btn-border-radius);
            display: block;
            padding: 5px 12px;
            border: 1px solid var(--cx-color-gray);
            margin-bottom: 8px;
            cursor: pointer;

            &.selected {
              @include valio-bold-link-mini-14();
              display: block;
              border: 1px solid var(--cx-color-text);

              .cx-facet-text {
                .valio-icon {
                  display: block;
                }
              }
            }

            .cx-facet-text {
              .valio-icon {
                display: none;
                float: right;
              }
            }
          }

          &.active-facet {
            display: block;
          }

          .cx-facet-group {
            li {
              display: block;
            }
          }
        }
      }
    }

    .cx-facet-mobile {
      display: none;

      @include media-breakpoint-down(sm) {
        display: block;
      }

      .cx-facet-mobile-btn {
        @include valio-link();
        @include valio-copy-mini-14();
        @include valio-border-radius($valio-btn-border-radius);
        padding: 5px 16px;
        border: 1px solid var(--cx-color-gray);
        box-shadow: 0 4px 24px rgba(220, 216, 254, 0.1);
        cursor: pointer;

        .valio-icon {
          pointer-events: none;
        }
      }
    }

    .valio-sorts {
      display: inline-block;

      .ng-select {
        &.ng-select-opened {
          > .ng-select-container {
            .ng-arrow {
              top: 0;
            }
          }
        }

        .ng-select-container {
          @include valio-link();
          @include valio-copy-mini-14();
          @include valio-border-radius($valio-btn-border-radius);
          padding: 5px 16px;
          border: 1px solid var(--cx-color-gray);
          background: var(--cx-color-transparent);
          box-shadow: 0 4px 24px rgba(220, 216, 254, 0.1);
          cursor: pointer;
          height: auto;

          .ng-value-container,
          .ng-arrow-wrapper {
            padding: 0;
          }
        }

        .ng-dropdown-panel {
          @include valio-copy-mini-14();
          margin-top: 8px;
          padding: 8px 0;
          box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.07);
          border: none;

          .ng-dropdown-panel-items {
            .ng-option {
              padding: 8px 16px;

              &.ng-option-marked {
                background: var(--cx-color-transparent);
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

.cx-facet-modal-body {
  .cx-facet-modal-label {
    @include valio-link-bold-18();
  }

  .cx-facet-list {
    padding: 0;

    li {
      @include valio-copy-mini-14();
      @include valio-border-radius($valio-btn-border-radius);
      display: block;
      padding: 5px 12px;
      border: 1px solid var(--cx-color-gray);
      margin-bottom: 8px;
      cursor: pointer;

      &.selected {
        @include valio-bold-link-mini-14();
        display: block;
        border: 1px solid var(--cx-color-text);

        .cx-facet-text {
          .valio-icon {
            display: block;
          }
        }
      }

      .cx-facet-text {
        .valio-icon {
          display: none;
          float: right;
        }
      }
    }

    &.active-facet {
      display: block;
    }

    .cx-facet-group {
      li {
        display: block;
      }
    }
  }
}

.pagination-pages {
  padding: 8px 0 24px;

  .pagination-link {
    padding: 0 16px;
  }
}
