// Background color for purchase related page templates
.CartPageTemplate,
.MultiStepCheckoutSummaryPageTemplate,
.OrderConfirmationPageTemplate {
  @include valio-background-color(var(--cx-color-nude));
}

.OrderTemplatePageTemplate {
  @include valio-background-color(var(--cx-color-light-peach));
}

valio-cx-order-history,
valio-deliverynote-details {
  @include valio-background-color(var(--cx-color-pink));
}

.ContentPageBlueTemplate {
  @include valio-background-color(var(--cx-color-alice-blue));
}
