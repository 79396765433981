@mixin valio-single-column-layout() {
  @include valio-flex-box();
  @include valio-flex-direction-column();
  margin: 0 auto;
  flex-wrap: wrap;
  width: 100%;
  max-width: none;
  padding: 0;
}

@mixin valio-two-column-layout() {
  @include valio-flex-box();
  @include valio-flex-direction-column();
  margin: 0 auto;
  flex-wrap: wrap;
  width: 100%;
  max-width: $valio-page-lg-max-width;

  @include media-breakpoint-up(lg) {
    @include valio-flex-direction-row();
    flex-wrap: nowrap;
  }
}

@mixin valio-section1() {
  @include valio-flex-1();
  @include valio-align-content(flex-start);
  @include valio-order-1();
  max-width: 100%;
  margin: 0;
}

@mixin valio-calendar-side-panel() {
  @include valio-order-2();
  background-color: var(--cx-color-light-peach);

  @include media-breakpoint-down(md) {
    margin: 48px;
  }

  @include media-breakpoint-down(sm) {
    margin: 0;
  }

  &:empty {
    display: none;
  }
}

@mixin valio-side-panel() {
  @include valio-order-1();

  &:empty {
    display: none;
  }
}

@mixin valio-body-section() {
  @include valio-flex-1();
  @include valio-align-content(flex-start);
  @include valio-order-2();
  max-width: 100%;
  margin: 0;
}

@mixin valio-background-color($color: 'transparent') {
  &:before {
    content: " ";
    background-color: $color;
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
}

@mixin valio-side-panel-content() {
  background-color: var(--cx-color-white);
  padding: 40px 16px;
  z-index: 10;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 280px;
    padding: 40px 24px;
  }

  @include media-breakpoint-up(lg) {
    width: 336px;
    padding: 40px 32px 40px 48px;
  }
}

@mixin valio-container-paddings() {
  padding: 0 $valio-container-padding-sm $valio-container-padding-sm $valio-container-padding-sm;

  @include media-breakpoint-up(md) {
    padding: 0 $valio-container-padding $valio-container-padding $valio-container-padding;
  }
}

@mixin valio-content-margins() {
  margin: 0 $valio-content-margin-sm;

  @include media-breakpoint-up(md) {
    margin: 0 $valio-content-margin;
  }
}

@mixin valio-supsub() {
  .supsub {
    display: inline-block;

    sup,
    sub {
      position: relative;
      display: block;
    }
  }
}

@mixin valio-page-header() {
  @include valio-flex-box();
  @include valio-align-items(center);
  @include valio-align-content(center);
  padding: 64px 0;

  .page-header-back {
    position: absolute;

    a {
      @include valio-bold-link-mini-14();
      cursor: pointer;

      span {
        vertical-align: middle;
      }
    }
  }

  .page-header-title {
    width: 85%;
    margin: auto;

    @include media-breakpoint-up(md) {
      width: 75%;
    }

    @include media-breakpoint-up(xl) {
      width: 85%;
    }

    h1 {
      text-align: center;
    }

    .narrow-description {
      max-width: 500px;
      margin: auto;
    }
  }
}

@mixin valio-my-account-menu() {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      @include valio-link();
      @include valio-copy-small-16();
      border-bottom: 1px solid var(--cx-color-iris);
      padding: 24px 0;
      cursor: pointer;
      white-space: nowrap;

      &:focus {
        outline: none;
      }

      &.active {
        @include valio-link-bold-small-16();
      }

      a {
        &.active {
          @include valio-link-bold-small-16();
        }
      }
    }
  }
}

@mixin valio-product-carousel-header() {
  @include valio-link-bold-18();
  margin: 16px 0;
}

@mixin valio-scrollbar() {
  -ms-overflow-style: -ms-autohiding-scrollbar; // Edge
  scrollbar-color: var(--cx-color-transparent) var(--cx-color-transparent); // FF

  > div {
    scrollbar-width: thin; // FF
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    @include valio-border-radius(20px !important);
    background-color: var(--cx-color-transparent);
  }

  &:hover {
    scrollbar-color: var(--cx-color-rosa) var(--cx-color-transparent); // FF

    ::-webkit-scrollbar-thumb {
      background-color: var(--cx-color-rosa);
    }
  }
}


@mixin valio-cart-header-products-totals-mixin() {
  .products {
    text-align: left;

    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }

  .totals {
    white-space: nowrap;
    text-align: right;

    @include media-breakpoint-down(sm) {
      @include valio-flex-box();
      @include valio-flex-direction-column();
    }

    .cx-vat {
      text-align: right;
      margin-left: 24px;

      @include media-breakpoint-down(md) {
        float: left;
      }

      @include media-breakpoint-down(sm) {
        @include valio-order-2();
      }

      @include media-breakpoint-up(md) {
        display: inline-block;
      }

      @include media-breakpoint-up(lg) {
        width: 64px;
        margin-right: 16px;
        margin-left: 8px;
      }
    }

    .cx-price {
      @include valio-copy-18();
      display: inline-block;
      text-align: right;

      @include media-breakpoint-up(md) {
        @include valio-link-bold-small-16();
        line-height: 20px;
      }

      @include media-breakpoint-up(lg) {
        @include valio-bold-link-mini-14();
        line-height: 20px;
        width: 40%;
      }
    }
  }
}

@mixin valio-flex-box() {
  display: -webkit-box; /* Safari 3.1 - 6, Chrome < 21 (2009 Spec), UC Browser Android */
  display: -moz-box; /* Firefox 2 - 27 (2009 Spec), UC Mini */
  display: -ms-flexbox; /* IE10 (2012 Syntax) */
  display: -webkit-flex; /* Safari 6.1 - 8, Android < 4.4, BB < 10, Chrome 21 - 28 */
  display: flex; /* Edge 12+, Firefox 28+, Blink, Safari 9+, Opera Mini 8+ */
}

@mixin valio-flex-direction-column() {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-direction: normal;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
}

@mixin valio-flex-direction-row() {
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-direction: normal;
  -moz-box-orient: horizontal;
  -ms-box-orient: horizontal;
  box-orient: horizontal;
}

@mixin valio-flex-1-0-auto() {
  -webkit-box: 1;
  -moz-box: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

@mixin valio-flex-1() {
  -webkit-box: 1;
  -moz-box: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

@mixin valio-flex-0() {
  -webkit-box: 0;
  -moz-box: 0;
  -webkit-flex: 0;
  -ms-flex: 0;
  flex: 0;
}

@mixin valio-order-1() {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
}

@mixin valio-order-2() {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
}

@mixin valio-flex-shrink() {
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex: 0;
  flex-shrink: 0;
}

@mixin valio-justify-content($position: 'center') {
  -webkit-justify-content: $position;
  -moz-justify-content: $position;
  -ms-justify-content: $position;
  justify-content: $position;
}

@mixin valio-align-items($position: 'center') {
  -webkit-align-items: $position;
  -moz-align-items: $position;
  -ms-align-items: $position;
  align-items: $position;
}

@mixin valio-align-content($position: 'center') {
  -webkit-align-content: $position;
  -moz-align-content: $position;
  -ms-align-content: $position;
  align-content: $position;
}

@mixin valio-border-radius($size: 0) {
  -webkit-border-radius: $size;
  -moz-border-radius: $size;
  border-radius: $size;
}

@mixin hideWrapperDesktop {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  margin-left: -10px;
  border: 1px solid #ccc;
}

@mixin showWrapperDesktop {
  opacity: 1;
  visibility: visible;
}
