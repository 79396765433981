.remove-date {
  background: var(--cx-color-white);
  line-height: $valio-input-height-sm;
  padding: 16px 24px;

  &.collapsed {
    display: none;
  }
}

.cart-details-wrapper {
  background-color: var(--cx-color-white);

  .product-type {
    padding: 40px;

    h3 {
      @include valio-H3-24();
    }

    .product-type-totals {
      @include valio-copy-small-16();
      color: var(--cx-color-primary);
      text-align: right;

      .cx-price {
        @include valio-link-bold-18();
        bottom: 3px;
      }
    }
  }

  .cart-entry-block {
    &:last-child {
      .partner-row {
        border-bottom: none;
      }
    }

    .date-header {
      @include valio-border-radius($valio-btn-border-radius);
      background-color: $valio-light-purple;
      padding: 20px;
    }

    .partner-row {
      @include valio-copy-mini-14();
      padding: 16px 0;
      cursor: pointer;
      background-color: var(--cx-color-pink);
      border-bottom: 1px solid var(--cx-color-white);

      @include media-breakpoint-down(sm) {
        @include valio-bold-link-mini-14();
      }

      @include media-breakpoint-up(lg) {
        padding: 0;
      }

      .partner-toggle {
        @include media-breakpoint-down(sm) {
          margin-left: 10px;
        }
      }

      .payment-mode-change {
        font-weight: bold;
        cursor: pointer;
      }

      .cx-name {
        @include valio-copy-18();
        display: inline-block;
        vertical-align: middle;

        @include media-breakpoint-down(sm) {
          margin-left: 4px;
        }

        @include media-breakpoint-up(md) {
          @include valio-link-bold-18();
        }
      }

      .column-separator {
        @include media-breakpoint-up(lg) {
          border-left: 1px solid var(--cx-color-iris);
          height: $valio-input-height-lg;
        }
      }

      @include valio-cart-header-products-totals-mixin();

      .totals {
        .cx-price {
          @include media-breakpoint-up(md) {
            @include valio-link-bold-18();
          }
        }
      }
    }

    .cx-item-list-row {
      @include valio-copy-mini-14();
      background-color: var(--cx-color-white);

      .cx-item-list-items {
        &:last-child {
          padding-bottom: 32px;
        }

        .cx-item {
          border-bottom: 1px solid var(--cx-color-iris);
          margin: 0 16px;

          @include media-breakpoint-up(lg) {
            margin: 0 24px;
          }

          .cart-item-message {
            .message {
              display: inline-block;
            }
          }
        }

        a {
          color: var(--cx-color-black);
        }

        p {
          margin-bottom: 4px;
        }

        .cx-price {
          p {
            display: inline;
          }
        }

        .voucher {
          color: var(--cx-color-alarm-discount);
          font-weight: bold;
        }

        .cx-code {
          padding-top: 8px;
        }

        .cx-quantity {
          .cart-item-quantity {
            display: inline-block;
            margin-left: 32px;
            margin-right: 8px;
            width: 88px;

            @include media-breakpoint-down(sm) {
              margin-left: 8px;
              width: 112px;
            }
          }

          .school-milk {
            line-height: 42px;
            width: 100px;
            display: inline-block;
            white-space: nowrap;

            input {
              @include valio-border-radius($valio-btn-border-radius);
              margin: 0;
              vertical-align: middle;
              position: relative;
              height: 24px;
              width: 24px;
            }

            label {
              display: inline-block;
              vertical-align: middle;
              padding-left: 8px;
              margin: 0;
            }
          }
        }
      }
    }

    .totals {
      .weight-totals {
        white-space: nowrap;
        text-align: right;

        @include media-breakpoint-down(md) {
          @include valio-flex-box();
          @include valio-flex-direction-row();
        }

        @include media-breakpoint-down(sm) {
          @include valio-bold-link-mini-14();
        }

        .weight-unit {
          @include valio-order-2();

          @include media-breakpoint-up(lg) {
            @include valio-bold-link-mini-14();
            display: block;
          }
        }

        .weight-total {
          @include valio-order-1();
          margin-right: 4px;

          @include media-breakpoint-up(lg) {
            display: block;
            margin-right: 0;
          }
        }
      }

      .cx-total {
        text-align: right;

        .cx-vat {
          @include media-breakpoint-down(sm) {
            @include valio-bold-link-mini-14();
          }
        }

        .cx-price {
          @include valio-copy-18();

          @include media-breakpoint-up(md) {
            @include valio-link-bold-18();
          }

          @include media-breakpoint-up(lg) {
            @include valio-bold-link-mini-14();
          }
        }
      }
    }

    valio-cx-cart-item {
      .has-errors {
        .product-image-description,
        .product-prices,
        .totals {
          opacity: 0.5;
        }

        .cx-name {
          text-decoration: line-through;
        }
      }

      .product-image-description {
        @include valio-flex-box();
        @include valio-flex-direction-row();
        flex-wrap: nowrap;

        .cx-image-container {
          @include valio-flex-0();
          display: inline-block;
          min-width: 67px;
          margin-right: 16px;

          @include media-breakpoint-up(md) {
            min-width: 83px;
          }

          cx-media {
            text-align: center;

            &.is-missing {
              &[format='cartIcon'] {
                @include valio-border-radius($valio-btn-border-radius);
                background-color: var(--cx-color-product-image-background);
                background-size: 60px;
                min-height: 67px;

                @include media-breakpoint-up(md) {
                  background-size: 75px;
                  min-height: 83px;
                }
              }
            }

            img {
              @include valio-border-radius($valio-btn-border-radius);
              max-height: 67px;
              width: 100%;
              object-fit: contain;

              @include media-breakpoint-up(md) {
                max-height: 83px;
              }
            }
          }
        }

        .product-description {
          @include valio-flex-1();

          .cx-name {
            display: inline-block;

            @include media-breakpoint-up(md) {
              @include valio-bold-link-mini-14();
            }
          }

          .cx-code {
            @include media-breakpoint-down(sm) {
              @include valio-bold-link-mini-14();
            }
          }
        }
      }

      .po-number {
        width: 110px;
        margin-bottom: 0;
      }

      .cart-item-status-actions {
        @include valio-flex-box();
        @include valio-flex-direction-row();
        flex-wrap: nowrap;

        .cart-item-status-bg {
          @include valio-flex-1();
          align-self: center;

          @include media-breakpoint-down(md) {
            @include valio-border-radius($valio-btn-border-radius);
            padding: 8px 16px;
            background-color: var(--cx-color-product-image-background);
          }

          .cart-item-status,
          .cart-item-closing-time {
            @include media-breakpoint-down(md) {
              display: inline-block;
              line-height: 24px;
            }
          }

          .closing-time-passed {
            color: var(--cx-color-alarm-discount);
          }

          .closing-time-about-to-pass {
            color: var(--cx-color-alarm-warning);
          }

          .cart-item-closing-time {
            @include valio-copy-mini-14();
            padding-left: 8px;

            @include media-breakpoint-up(lg) {
              padding-left: 16px;
            }

            .closing-item {
              @include media-breakpoint-up(lg) {
                display: block;
              }
            }
          }

          + .cart-item-actions {
            @include media-breakpoint-down(md) {
              margin-left: 16px;
            }
          }
        }

        .cart-item-actions {
          @include valio-flex-0();

          .delete-icon {
            @include media-breakpoint-down(md) {
              background-color: var(--cx-color-light-purple);
            }
          }
        }
      }

      .deleted {
        opacity: 0.5;
      }
    }
  }

  .cart-entry-totals {
    @include valio-copy-small-16();
    color: var(--cx-color-purple);
    padding: 0 16px 24px;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    display: block;
    z-index: 2;
    width: 100%;
    background-color: var(--cx-color-white);
    margin-bottom: -24px;

    @include media-breakpoint-up(md) {
      padding: 0 16px 32px;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 32px 40px;
    }

    > .row {
      border-top: 2px solid var(--cx-color-purple);
    }

    .cart-confirm-text {
      @include valio-copy-mini-14();
      color: var(--cx-color-text);
      margin: 0;

      @include media-breakpoint-down(sm) {
        margin-top: 8px;
      }
    }
  }

  .add-multiple-products {
    padding: 16px;

    @include media-breakpoint-up(md) {
      padding: 16px 16px 56px 16px;
    }

    @include media-breakpoint-up(lg) {
      padding: 16px 96px 56px 96px;
    }

    .add-multiple-products-bg {
      background: var(--cx-color-pink);
      text-align: center;
      padding: 16px;

      @include media-breakpoint-up(md) {
        padding: 24px;
      }

      .template-selector,
      .file-import {
        display: inline-block;
        width: 100%;
        padding: 16px;
        vertical-align: top;

        @include media-breakpoint-up(md) {
          padding: 16px 32px;
          width: 50%;
        }

        &:empty {
          padding: 0;
        }
      }

      .template-selector {
        + .file-import {
          border-top: 1px solid var(--cx-color-iris);

          @include media-breakpoint-up(md) {
            border-top: none;
            border-left: 1px solid var(--cx-color-iris);
          }
        }

        .ng-select {
          display: inline-block;
          margin-right: 16px;
          vertical-align: top;
          text-align: left;

          .ng-select-container {
            height: $valio-input-height-sm;
            background: var(--cx-color-transparent);
          }
        }
      }
    }
  }
}

valio-cx-cart-item-list {
  .collapsed {
    display: none;
  }
}

.cart-details-wrapper {
  &.collapsed {
    display: none;
  }
}

.cart-item-message {
  padding: 8px 0;
}

.cart-message {
  .message {
    margin-bottom: 8px;
  }
}

.message {
  @include valio-border-radius($valio-btn-border-radius);
  background: none repeat scroll 0 0;
  width: 100%;
  border: 1px solid var(--cx-color-text);
  padding: 8px;
  color: var(--cx-color-white);

  + .message {
    margin-top: 8px;
    padding-bottom: 16px;
  }

  .action {
    float: right;
  }

  button {
    color: var(--cx-color-white);
    @include valio-bold-link-mini-14();
    background: var(--cx-color-transparent);
    text-decoration: none;
    padding: 0 8px;

    &:first-child {
      @include valio-border-radius(0 $valio-btn-border-radius $valio-btn-border-radius 0);
      border-left: 1px solid var(--cx-color-white);
    }
  }

  &.ERROR {
    background-color: var(--cx-color-alarm-discount);
    border-color: var(--cx-color-alarm-discount);
  }

  &.WARNING {
    background-color: var(--cx-color-alarm-warning);
    border-color: var(--cx-color-alarm-warning);
  }

  &.INFO {
    background-color: var(--cx-color-alarm-info);
    border-color: var(--cx-color-alarm-info);
  }

  &.message-numbered {
    position: relative;
    background: var(--cx-color-white);

    .message-number {
      @include valio-border-radius($valio-btn-border-radius 0 0 $valio-btn-border-radius);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 24px;
    }

    .message-text {
      color: var(--cx-color-text);
      padding-left: 32px;
      text-align: left;
    }
  }
}

.cart-item-status {
  @include valio-bold-link-mini-14();
  white-space: nowrap;

  .dot {
    @include valio-border-radius(50%);
    height: 8px;
    width: 8px;
    display: inline-block;
    margin-right: 8px;

    &.open {
      background-color: var(--cx-color-alarm-warning);
    }

    &.billed {
      background-color: var(--cx-color-black);
    }

    &.approved {
      background-color: var(--cx-color-status-done);
    }

    &.empty {
      background-color: var(--cx-color-black);
    }

    &.error {
      background-color: var(--cx-color-red);
    }
  }
}

valio-cx-switch-payment {
  .switchPayment {
    width: 420px;
  }
}
