//
// Headings
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0;
}

h1, .h1 {
  @include valio-H1-38();

  @include media-breakpoint-down(md) {
    @include valio-H3-24();
  }
}

h2, .h2,
h3, .h3 {
  @include valio-H3-24();

  @include media-breakpoint-down(md) {
    @include valio-link-bold-18();
  }
}

h4, .h4 {
  @include valio-link-bold-18();

  @include media-breakpoint-down(md) {
    @include valio-link-bold-18();
  }
}

h5, .h5 {
  @include valio-link-bold-small-16();

  @include media-breakpoint-down(md) {
    @include valio-link-bold-small-16();
  }
}

h6, .h6 {
  @include valio-bold-link-mini-14();

  @include media-breakpoint-down(md) {
    @include valio-bold-link-mini-14();
  }
}

p {
  margin-bottom: 16px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 24px;
  }
}

b,
strong,
.bold,
.text-bold {
  @include valio-bold-link-mini-14();
}

.small-text {
  @include valio-tiny-12();
}

.text-right-xs-down {
  @include media-breakpoint-down(xs) {
    text-align: right;
  }
}
