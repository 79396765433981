valio-deliverynote-datepicker-form {
  .form-inline {
    .form-row {
      margin: 0;
      width: 100%;

      label {
        @include valio-justify-content(normal);
      }

      input {
        &.form-control {
          width: 100%;
        }
      }
    }
  }
}
