body {
  &.has-searchbox-results {
    header {
      z-index: 200;
    }

    valio-cx-searchbox {
      @include valio-scrollbar();

      .result-wrapper {
        @include valio-border-radius($valio-btn-border-radius);
        display: block;
        position: absolute;
        z-index: -1;
        width: 100%;
        max-width: 752px;
        background-color: var(--cx-color-white);
        box-shadow: 4px 4px 25px 0 rgba(0, 0, 0, 0.1);

        .results {
          display: block;
          position: relative;
          max-height: 432px;
          overflow: auto;

          .message {
            @include valio-copy-mini-14();
            color: var(--cx-color-text);
            border: none;
            padding: 24px 16px;
          }

          .products {
            &:not(:empty) {
              padding-top: 16px;
            }

            .search-result-anchor {
              display: block;

              &.active,
              &:hover {
                text-decoration: none;

                .product {
                  @include valio-bold-link-mini-14();
                  background-color: var(--cx-color-pink);
                }
              }

              .product {
                padding: 8px 24px;
                white-space: nowrap;

                .image {
                  position: relative;
                  vertical-align: top;

                  cx-media {
                    height: 45px;
                    width: 45px;
                    text-align: center;
                    cursor: pointer;

                    &.is-missing {
                      @include valio-border-radius($valio-btn-border-radius);
                      background-color: var(--cx-color-product-image-background);
                      background-size: 38px;
                      max-width: 45px;
                      max-height: 45px;
                      min-height: 45px;
                    }

                    img {
                      max-width: 45px;
                      max-height: 45px;
                      min-height: 45px;
                      object-fit: contain;
                    }
                  }
                }

                .code {
                  @include valio-tiny-12();
                  color: var(--cx-color-gray);
                }

                .name {
                  @include valio-copy-mini-14();

                  em {
                    &.search-results-highlight {
                      @include valio-bold-link-mini-14();
                    }
                  }
                }
              }
            }
          }
        }

        .sticky-results {
          border-top: 1px solid var(--cx-color-line);
          background: var(--cx-color-white);
          padding: 8px 0;
          margin: 0 24px;
        }
      }
    }
  }
}

valio-cx-searchbox,
.valio-cx-searchbox,
valio-cx-global-searchbox {
  z-index: 10;
  display: block;

  @include media-breakpoint-up(lg) {
    position: relative;
  }

  > * {
    background-color: var(--cx-color-white);
    z-index: 20;
  }

  label {
    @include valio-flex-box();
    @include valio-align-content(stretch);
    @include valio-border-radius($valio-btn-border-radius);
    margin: 0;
    padding: 10px 16px;
    width: auto;
    box-shadow: 0 4px 14px rgba(45, 21, 116, 0.1);

    @include media-breakpoint-up(lg) {
      min-width: 300px;
    }

    input {
      @include valio-copy-small-16();
      background: none;
      border: none;
      outline: none;
      display: block;
      flex-basis: 100%;
      z-index: 20;

      @include media-breakpoint-down(md) {
        position: static;
        background: none;
        border: none;
        padding: 0;
        left: 0;
        top: 57px;
        width: 100%;
      }
    }

    // hide search icon when the input is dirty
    &.dirty {
      .search {
        display: none;
      }
    }

    // hide reset icon when the input is empty
    &:not(.dirty) {
      .search-open,
      .reset {
        display: none;
      }
    }

    .search,
    .search-open,
    .reset {
      .valio-icon {
        width: $valio-input-height-sm;
        height: $valio-input-height-sm;
        line-height: $valio-input-height-sm;
        display: block;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.HeaderLinks {
  valio-cx-global-searchbox {
    position: relative;
    z-index: 200;
    top: auto;
    display: inline-block;
    background-color: var(--cx-color-transparent);


    @include media-breakpoint-up(lg) {
      display: none;
    }

    .searchbox {
      padding: 8px 24px 8px 8px;

      &.dirty,
      &.open {
        @include media-breakpoint-down(sm) {
          width: 100vw;
        }

        input {
          opacity: 1;
        }
      }

      input {
        opacity: 0;
        width: 0;
        min-width: 0;
      }
    }

    .results {
      left: auto;
      top: auto;
      right: 0;
      z-index: 200;

      @include media-breakpoint-down(xs) {
        left: -75px;
      }

      @include media-breakpoint-down(sm) {
        left: -90px;
        width: 100vw;
        min-width: auto;
      }

      .products {
        .search-result-anchor {
          .product-row {
            .add-to-cart {
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}

valio-cx-global-searchbox {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--cx-color-main-navigation-background);

  > * {
    background-color: var(--cx-color-transparent);
  }

  .searchbox {
    box-shadow: none;
    min-width: auto;
    padding: 8px;
    cursor: pointer;

    ::-webkit-input-placeholder {
      color: var(--cx-color-text);
    }

    :-moz-placeholder { /* Firefox 18- */
      color: var(--cx-color-text);
    }

    ::-moz-placeholder { /* Firefox 19+ */
      color: var(--cx-color-text);
    }

    :-ms-input-placeholder {
      color: var(--cx-color-text);
    }

    &.dirty,
    &.open {
      .search-open {
        display: inline-block;
      }

      .search {
        display: none;
      }

      .reset {
        position: absolute;
        right: 0;
        z-index: 30;
      }

      input {
        text-align: left;
        min-width: 200px;
      }
    }

    .search-open {
      display: none;
    }

    .search {
      display: inline-block;
    }

    input {
      @include valio-copy-mini-14();
      color: var(--cx-color-text);
      text-align: right;
      height: auto;
      width: 85px;
      min-width: 85px;
      -webkit-transition-property: all;
      -webkit-transition-duration: .2s;

      @include media-breakpoint-up(xl) {
        width: auto;
        min-width: 150px;
      }
    }
  }

  .results {
    position: absolute;
    left: 0;
    top: 55px;
    padding: 24px 0;
    background-color: var(--cx-color-white);
    min-width: 568px;
    z-index: 80;
    box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.1);

    @include media-breakpoint-up(xl) {
      left: auto;
      right: 0;
    }

    .message {
      @include valio-copy-mini-14();
      color: var(--cx-color-text);
      border: none;
      padding: 0;
      margin: 0 24px 24px;
    }

    .products {
      .result-header {
        @include valio-tiny-12();
        color: var(--cx-color-gray);
        margin: 8px 24px;
      }

      .result-separator {
        margin: 16px 24px 24px;
        border-top: 1px solid var(--cx-color-line);
      }

      .search-result-anchor {
        padding: 0 24px;

        &:hover {
          background: var(--cx-color-pink);
        }

        .product-row {
          padding: 8px 0;

          &.old-query-icon {
            padding-left: 24px;
            min-height: 24px;
            background: no-repeat left center url("data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M7.09564%2016C4.15164%2016%201.44765%2014.384%200.0476456%2011.792C-0.0563544%2011.6%200.0156553%2011.352%200.207655%2011.248C0.399655%2011.144%200.64765%2011.216%200.75165%2011.408C2.00765%2013.744%204.43964%2015.2%207.09564%2015.2C11.0636%2015.2%2014.2956%2011.968%2014.2956%208C14.2956%204.032%2011.0636%200.8%207.09564%200.8C4.45564%200.8%202.02364%202.23997%200.767641%204.56797C0.671641%204.74397%200.463652%204.82395%200.279652%204.75195C0.0956524%204.67995%20-0.0163456%204.48809%200.0316544%204.29609L0.711659%201.11992C0.759659%200.903922%200.967656%200.768016%201.18366%200.816016C1.39966%200.864016%201.53564%201.07209%201.48764%201.28809L1.20766%202.58398C2.70366%200.959984%204.83164%200%207.09564%200C9.23164%200%2011.2397%200.831945%2012.7517%202.34395C14.2637%203.85595%2015.0956%205.864%2015.0956%208C15.0956%2010.136%2014.2637%2012.1441%2012.7517%2013.6561C11.2397%2015.1681%209.23164%2016%207.09564%2016Z%22%20fill%3D%22%239695A4%22%2F%3E%0A%3Cpath%20d%3D%22M7.09475%208.39996C7.03075%208.39996%206.95875%208.38391%206.90275%208.35191L3.30275%206.35191C3.11075%206.24791%203.03875%205.99997%203.15075%205.80797C3.25475%205.61597%203.50275%205.54401%203.69475%205.65601L7.08674%207.5439L11.2867%204.87203C11.4707%204.75203%2011.7187%204.80795%2011.8387%204.99195C11.9587%205.17595%2011.9027%205.4239%2011.7187%205.5439L7.31874%208.3439C7.24674%208.3759%207.16675%208.39996%207.09475%208.39996Z%22%20fill%3D%22%239695A4%22%2F%3E%0A%3C%2Fsvg%3E%0A");

            .link {
              @include valio-copy-mini-14();
              @include valio-link();
            }
          }

          .image {
            cx-media {
              height: 45px;
              width: 45px;
              text-align: center;
              cursor: pointer;

              &.is-missing {
                @include valio-border-radius($valio-btn-border-radius);
                background-color: var(--cx-color-product-image-background);
                background-size: 38px;
                max-width: 45px;
                max-height: 45px;
                min-height: 45px;
              }

              img {
                max-width: 45px;
                max-height: 45px;
              }
            }

            .code {
              @include valio-copy-mini-14();
              @include valio-link();
              color: var(--cx-color-gray);
              text-align: left;
            }
          }

          .item {
            @include valio-link();

            .code {
              @include valio-tiny-12();
              color: var(--cx-color-gray);
            }

            .name {
              @include valio-copy-mini-14();
            }
          }

          .add-to-cart {
            padding-top: 16px;

            .link {
              @include valio-bold-link-mini-14();
              @include valio-link();
              color: var(--cx-color-purple);
            }
          }
        }
      }
    }
  }
}
