@media print {
  .OrderConfirmationPageTemplate,
  .CartPageTemplate,
  .MultiStepCheckoutSummaryPageTemplate {
    valio-cx-order-confirmation-thank-you-message,
    .page-header,
    .cart-confirm-text,
    .submit {
      display: none;
    }

    .cx-order-items-header {
      @include valio-bold-link-mini-14();
      margin: 0;
    }

    .print-customer-info {
      @include valio-tiny-12();
      position: absolute;
      right: 0;
    }

    .cart-header-wrapper {
      .header-info-slot {
        color: var(--cx-color-black);
        background-color: transparent;
        margin-bottom: 4px;
        padding: 0 !important;

        .cx-date {
          .day {
            display: none;
          }

          .supsub {
            padding-left: 0;
          }
        }
      }
    }

    .cart-details-wrapper {
      &.collapsed {
        display: block !important;
      }

      valio-cx-cart-item-list {
        .collapsed {
          display: block !important;
        }
      }

      .padding-top-sm,
      .padding-top-base {
        padding-top: 0;
      }

      .margin-bottom-base {
        margin-bottom: 0;
      }

      .margin-left-base {
        margin-left: 0;
      }

      .submit-row {
        padding: 10px 0;

        .submit-title, .products {
          @include valio-bold-link-mini-14();
        }

        .totals {
          .cx-price {
            margin-left: 24px;
          }

          .cx-vat {
            @include valio-copy-mini-14();
            display: inline-block;
            float: none;
          }
        }
      }

      .cx-price {
        font-size: 14px !important;
        line-height: 125% !important;
      }

      .print-item {
        -webkit-box-flex: 1;
        -ms-flex: 0 0 40%;
        flex: 0 0 40%;
        max-width: 40%;
      }

      .print-quantity {
        -webkit-box-flex: 1;
        -ms-flex: 0 0 60%;
        flex: 0 0 60%;
        max-width: 60%;

        .input-label {
          padding: 0;
        }

        .weight-totals {
          @include valio-copy-mini-14();
        }
      }

      .cart-entry-block {
        .partner-row {
          page-break-after: avoid;

          padding: 2px;
          -webkit-print-color-adjust: exact !important; /* Background color */

          .date-toggle,
          .partner-toggle {
            display: none !important;
          }

          .cx-name {
            @include valio-copy-small-16();
            margin-left: 0;
          }

          .print-total-items {
            display: inline-block !important;
            margin-left: 16px;
            vertical-align: middle;
          }

          .totals {
            display: inline-block;

            .cx-vat, .cx-price {
              @include valio-copy-mini-14();
              display: inline-block;
              float: none;
            }
          }
        }

        .cx-item-list-items {
          page-break-inside: avoid;

          .cx-item {
            padding: 4px 0;
            margin: 0;

            p {
              margin-bottom: 0;
            }

            .cart-item-message {
              display: none;
            }

            .cx-image-container {
              display: none;
            }

            .product-icon {
              &.icon-credit {
                -webkit-print-color-adjust: exact !important; /* Icon visibility on print */
              }
            }

            .cart-item-status-bg {
              padding: 0;
              text-align: right;
              background-color: transparent;

              .cart-item-status {
                @include valio-tiny-12();
                line-height: inherit;
              }

              .cart-item-closing-time {
                display: none;
              }
            }

            .product-prices {
              > p {
                display: inline-block;

                &:first-child {
                  padding-right: 32px;
                }
              }
            }

            .cx-total {
              .cx-vat {
                display: none;
              }
            }

            .cx-quantity {
              white-space: nowrap;

              .cx-label {
                display: none;
              }

              .cart-item-quantity {
                width: auto !important;
                padding-top: 0;
                vertical-align: middle;
              }

              .school-milk {
                width: auto !important;
              }
            }

            .print-status {
              position: relative;
              bottom: 35px;
              margin-bottom: -18px;
              right: 80px;
            }

            .product-description {
              .cx-name {
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .cx-code {
                padding: 0;
              }
            }
          }
        }
      }

      .cart-details-section {
        margin-bottom: 4px;

        .summary {
          .minicart-total-shipping {
            .total-label {
              @include valio-copy-mini-14();
            }

            .sum {
              @include valio-bold-link-mini-14();
            }
          }
        }
      }
    }
  }
}

