footer {
  cx-page-layout {
    &.footer {
      background-color: var(--cx-color-light-purple);
      color: var(--cx-color-text);

      valio-footer-component {
        padding: 88px 24px 24px;

        @include media-breakpoint-up(md) {
          padding: 88px 48px 72px;
        }

        @include media-breakpoint-up(lg) {
          padding: 88px 120px 40px;
        }

        .footer-top {
          @include valio-copy-18();
          padding: 32px 0 40px;
          border-bottom: 1px solid var(--cx-color-iris);

          @include media-breakpoint-up(lg) {
            padding: 72px 0 80px;
          }
        }

        .footer-bottom {
          @include valio-copy-small-16();
          padding: 40px 0 0;

          @include media-breakpoint-up(lg) {
            padding: 80px 0 0;
          }

          .row {
            @include media-breakpoint-up(md) {
              margin-left: -32px;
              margin-right: -32px;
            }

            @include media-breakpoint-up(lg) {
              margin-left: -48px;
              margin-right: -48px;
            }

            [class*='col-'] {
              @include media-breakpoint-up(md) {
                padding-right: 32px;
                padding-left: 32px;
              }

              @include media-breakpoint-up(lg) {
                padding-right: 48px;
                padding-left: 48px;
              }
            }
          }

          h5 {
            @include valio-link-bold-18();
            text-transform: uppercase;
            padding-bottom: 24px;
            margin-bottom: 16px;
            border-bottom: 1px solid var(--cx-color-iris);
          }

          a {
            @include valio-link-bold-small-16();
            color: var(--cx-color-text);
            padding: 0 0 24px;
          }

          valio-cx-navigation-ui {
            + p {
              margin-top: 24px;
            }

            cx-generic-link {
              @include media-breakpoint-down(md) {
                border-bottom: none;
              }
            }
          }

          valio-cx-site-context-selector {
            .site-context-item {
              @include valio-copy-small-16();

              + .site-context-item {
                &:before {
                  margin: 0 16px;
                }
              }
            }
          }

          .footer-functionality,
          .footer-products {
            padding-bottom: 64px;

            @include media-breakpoint-up(lg) {
              padding-bottom: 40px;
            }
          }
        }
      }
    }
  }
}
