valio-deliverynote-week-scroller {
  .valio-icon {
    cursor: pointer;
  }

  .bold-link {
    padding-left: 24px;
    padding-right: 24px;
  }
}
