valio-footer-logos-component {
  background-color: var(--cx-color-white);
  width: 100%;
  line-height: 110px;
  padding: 0 16px;

  @include media-breakpoint-up(sm) {
    line-height: 120px;
  }

  cx-generic-link {
    display: inline-block;
    width: 33.3%;
    text-align: center;

    @include media-breakpoint-up(sm) {
      width: 16.6%;
    }

    /* CSS trick to avoid single logo on it's own row */
    &:first-child:nth-last-child(7),
    &:first-child:nth-last-child(7) ~ cx-generic-link,
    &:first-child:nth-last-child(13),
    &:first-child:nth-last-child(13) ~ cx-generic-link {
      @include media-breakpoint-up(sm) {
        width: 14.28%;
      }
    }

    a {
      cursor: default;

      &:not(.btn) {
        cursor: default;
      }
    }

    cx-media {
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        max-width: 144px;
        max-height: 96px;
      }
    }
  }
}
