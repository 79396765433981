.LandingPage2Template {
  @include valio-two-column-layout();

  cx-page-slot {
    &.Section1 {
      @include valio-section1();
      @include valio-container-paddings();
      & {
        min-width: 0;
      }
      cx-banner,
      valio-main-navigation-component,
      valio-frontpage-component {
        @include valio-content-margins();
      }

      cx-banner {
        background-color: var(--cx-color-white);
        padding: 0 20px 20px;

        cx-media {
          img {
            width: 100%;
          }
        }
      }

      valio-last-purchases,
      valio-top-sellers,
      valio-bought-together {
        valio-cx-carousel {
          .carousel-panel {
            .carousel-scroll {
              &:not(.show-scroll) {
                padding: 0;
                margin: 0 -8px;
              }
            }
          }
        }
      }
    }

    &.SidePanel {
      @include valio-calendar-side-panel();
    }
  }

  valio-main-navigation-component {
    // IE 10 and above
    @media screen and (-ms-high-contrast: active) {
      width: 80%;
    }
  }
}
