valio-cx-register,
valio-cx-register-new,
valio-cx-register-existing {
  margin: auto;
}

valio-cx-register,
.new-contract-modal {
  .cx-register {
    @include valio-border-radius($valio-lg-border-radius);
    background-color: var(--cx-color-white);
    box-shadow: 7px 4px 64px rgba(45, 21, 115, 0.1);
    margin: 0;

    .register-intro {
      @include valio-border-radius($valio-lg-border-radius 0 0 $valio-lg-border-radius);
      background-color: var(--cx-color-secondary);
      padding: 48px 40px;

      @include media-breakpoint-down(xs) {
        @include valio-border-radius(0 0 $valio-lg-border-radius $valio-lg-border-radius);
        @include valio-order-2();
      }

      @include media-breakpoint-up(md) {
        padding: 140px 48px;
      }

      .register-intro-label {
        @include valio-link-bold-18;
        margin-bottom: 16px;
      }

      .register-intro-text {
        @include valio-copy-small-16();

        + .register-intro-label {
          margin-top: 32px;
          margin-bottom: 8px;
        }
      }
    }

    .register-select {
      padding: 48px 40px;

      @include media-breakpoint-down(xs) {
        @include valio-order-1();
      }

      @include media-breakpoint-up(md) {
        padding: 72px 48px;
      }

      .register-header {
        @include valio-link-bold-18;
        margin-bottom: 16px;
      }

      .register-select-label {
        @include valio-copy-18();
        margin-bottom: 48px;
      }
    }
  }

  valio-cx-register-new,
  valio-cx-register-existing,
  valio-cx-new-contract {
    .content-center {
      @include valio-border-radius($valio-lg-border-radius);
      padding: 32px 16px 40px 16px;

      @include media-breakpoint-up(sm) {
        padding: 56px 168px 80px 168px;
      }

      @include media-breakpoint-up(lg) {
        padding: 56px 248px 88px 248px;
      }
    }

    .last-step-content {
      @include valio-copy-18();
      text-align: center;

      h3 {
        margin: 24px 0 40px 0;
      }

      &.failed {
        @include valio-copy-small-16();
        position: relative;

        h1 {
          color: var(--cx-color-purple);
          padding: 112px 0 16px 0;
        }

        .cx-login-link {
          @include valio-bold-link-mini-14();
          margin-top: 24px;
        }

        .last-step-background {
          @include valio-border-radius(50%);
          position: absolute;
          padding-top: 64px;
          left: 0;
          right: 0;
          z-index: 0;

          .stripe-circle {
            @include valio-border-radius(50%);
            margin: auto;
            height: 277px;
            width: 277px;
            background: repeating-linear-gradient(
                -45deg,
                var(--cx-color-pink),
                var(--cx-color-pink) 5px,
                var(--cx-color-transparent) 5px,
                var(--cx-color-transparent) 10px);
          }
        }

        .last-step-block {
          position: relative;
          z-index: 1;

          > div {
            padding-bottom: 24px;
          }
        }

        .register-next {
          padding: 56px 0 56px 0;
          left: 0;
          right: 0;
          text-align: center;
        }
      }
    }

    .label {
      @include valio-copy-small-16();
      text-align: center;
    }

    .dots {
      text-align: center;
      margin: 24px 0 16px;

      .dot {
        @include valio-border-radius(50%);
        height: 8px;
        width: 8px;
        margin: 0 8px;
        display: inline-block;
      }

      .black {
        background-color: var(--cx-color-black);
      }

      .white {
        background-color: var(--cx-color-white);
        border: 1px solid var(--cx-color-gray);
      }
    }

    h3 {
      @include valio-H3-24();
      margin: 16px 0 32px 0;
      text-align: center;
    }

    .form-group {
      margin-bottom: 0;
      text-align: left;

      .vendor {
        display: block;
        margin-bottom: 16px;

        ng-select {
          &.form-control,
          &.form-control.is-invalid {
            padding: 0;
            margin-bottom: 0;
            height: $valio-input-height-lg;

            .ng-select-container {
              border: 1px solid var(--cx-color-transparent);
              height: 50px;

              input {
                height: 38px;
              }
            }
          }
        }
      }

      .remove {
        @include valio-bold-link-mini-14();
        position: relative;
        cursor: pointer;
        display: block;
        margin-top: -8px;
      }

      + .register-next,
      + .label-content {
        margin-top: 32px;
      }
    }

    .label-content {
      &.header {
        @include valio-link-bold-small-16();
        margin: 32px 0 16px 0;
      }
    }

    .text-content {
      @include valio-copy-mini-14();
      margin: 16px 0 24px 0;
    }

    .register-next {
      text-align: center;

      &.success {
        border-bottom: 1px solid var(--cx-color-gray);
        padding-bottom: 48px;
        margin: 48px 0;
      }

      .cx-login-link {
        @include valio-copy-mini-14();
        color: var(--cx-color-black);
        margin-top: 24px;

        span {
          margin-left: 4px;
        }
      }
    }

    .add-another {
      @include valio-bold-link-mini-14();
      margin: 16px 0 48px;
      cursor: pointer;
      text-align: left;
    }

    .CustomerServiceContact {
      @include valio-copy-mini-14();

      a {
        @include valio-bold-link-mini-14();
      }
    }
  }
}

valio-cx-register-new {
  .register-next {
    height: 150px;

    .register-back {
      top: 130px;
    }
  }
}

valio-cx-register-existing {
  .register-next {
    height: 120px;

    .register-back {
      top: 100px;
    }
  }
}

valio-cx-register-new,
valio-cx-register-existing {
  .register-next {
    @include media-breakpoint-up(md) {
      height: auto;
    }

    .register-back {
      width: 100px;
      text-align: left;
      position: relative;

      @include media-breakpoint-up(md) {
        top: 32px;
      }

      a {
        @include valio-bold-link-mini-14();
        cursor: pointer;
        display: block;
      }
    }
  }
}

.form-check {
  padding: 0;
  margin: 32px 0 56px;
  text-align: left;

  label {
    display: block;
    position: relative;
    padding-left: 32px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      input {
        & ~ .checkmark {
          background-color: var(--cx-color-cloud);
        }
      }
    }

    .form-check-label {
      @include valio-copy-mini-14();

      a {
        @include valio-bold-link-mini-14();
        color: var(--cx-color-black);
      }
    }

    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked {
        & ~ .checkmark {
          background-color: var(--cx-color-white);

          &:after {
            display: block;
          }
        }
      }
    }

    /* Create a custom checkbox */
    .checkmark {
      @include valio-border-radius($valio-btn-border-radius);
      position: absolute;
      top: 6px;
      left: 0;
      height: 25px;
      width: 25px;
      border: 1px solid var(--cx-color-black);
      background-color: var(--cx-color-white);

      &.is-invalid {
        border: 1px solid var(--cx-color-red);
      }

      /* Create the checkmark (hidden when not checked) */
      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 8px;
        top: 3px;
        width: 8px;
        height: 14px;
        border: solid var(--cx-color-primary);
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}
