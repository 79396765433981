valio-deliverynote-row {
  display: block;

  &:first-child {
    .table-row {
      .table-row-top-border {
        border-top: none;
      }
    }
  }

  &:last-child {
    .table-row {
      .table-row-border {
        border-bottom: none;
      }
    }
  }

  .table-row {
    .table-row-border {
      border-bottom: 1px solid var(--cx-color-iris);
      height: $valio-input-height-lg;
      line-height: $valio-input-height-lg;
      white-space: nowrap;

      @include media-breakpoint-down(sm) {
        border-bottom: none;
      }
    }

    .table-row-top-border {
      border-bottom: none;
      border-top: 1px solid var(--cx-color-iris);
    }
  }
}
