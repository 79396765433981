@media print {

  @page {
    size: 210mm 297mm;
    margin: 14mm;
  }

  header, footer {
    display: none;
  }

  .container {
    color: var(--cx-color-black);
    /* Prints in monochrome */
    filter: Gray(); /* IE4-8 and 9 */
    filter: url('#grayscale'); /* SVG version for IE10, Chrome 17, FF3.5, Safari 5.2 and Opera 11.6 */
    -webkit-filter: grayscale(100%); /* CSS3 filter, at the moment Webkit only. Prefix it for future implementations */
    filter: grayscale(100%); /* future-proof */
  }

  .QSIFeedbackButton,
  #cvc-container,
  .TopHeaderSlot {
    display: none !important;
  }

}
