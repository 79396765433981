.ProductGridPageTemplate,
.ProductListPageTemplate,
.SearchResultsGridPageTemplate,
.SearchResultsListPageTemplate {
  @include valio-two-column-layout();

  .ProductGridSlot,
  .ProductListSlot,
  .SearchResultsGridSlot,
  .SearchResultsListSlot {
    @include valio-section1();
    @include valio-container-paddings();
    & {
      min-width: 0;
    }
    valio-main-navigation-component,
    valio-cx-product-facet-navigation {
      @include valio-content-margins();
    }
  }

}


.ProductGridPageTemplate .SidePanel,
.ProductListPageTemplate .SidePanel,
.SearchResultsGridPageTemplate .SidePanel,
.SearchResultsListPageTemplate .SidePanel {
  @include valio-calendar-side-panel();
}




