valio-video-component {
  iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
  }

  &.se-storefront-component {
    display: block;

    iframe {
      pointer-events: none;
    }
  }
}
