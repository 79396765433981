@media print {
  .ProductDetailsPageTemplate {
    .MainNavigation {
      display: none;
    }

    cx-page-slot {
      &.Summary {
        background-color: var(--cx-color-transparent);

        valio-cx-add-to-cart {
          display: none;
        }
      }
    }
  }
}
