.LoginPageTemplate {
  padding: 0;

  cx-page-slot {
    width: 100%;
    max-width: $valio-page-sm-max-width;
    padding: 0;
    margin: auto;

    > * {
      max-width: var(--cx-flex-basis, 100%);
    }

    &.LeftContentSlot {
      @include valio-flex-1();
      padding-bottom: 96px;

      @include media-breakpoint-down(md) {
        padding: 16px;
      }

      valio-cx-update-password {
        background-color: transparent;
        box-shadow: none;

        .container-account {
          @include valio-border-radius($valio-lg-border-radius);
          background-color: var(--cx-color-white);
          box-shadow: 7px 4px 64px rgba(45, 21, 115, 0.1);
          padding: 32px 16px 40px 16px;
          max-width: unset;

          @include media-breakpoint-up(sm) {
            padding: 56px 168px 80px 168px;
          }

          @include media-breakpoint-up(lg) {
            padding: 72px 248px;
          }

          h3 {
            @include valio-H1-38();
          }
        }
      }
    }

    &.RightContentSlot {
      @include valio-flex-0();
    }
  }

  @include media-breakpoint-down(md) {
    --cx-max-width: 100%;
  }
}
