.AccountPageTemplate,
.OrderTemplatePageTemplate {
  @include valio-two-column-layout();

  & {
    padding: 0;
  }

  cx-page-slot {
    margin: 0;
    padding: 0;

    &.SideContent {
      @include valio-side-panel();
    }

    &.BodyContent {
      @include valio-body-section();
      @include valio-container-paddings();
    }
  }
}

.AccountPageTemplate {
  @include media-breakpoint-up(md) {
    @include valio-flex-direction-row();
    flex-wrap: nowrap;
  }

  cx-page-slot {
    &.BodyContent {
      @include valio-flex-direction-row();
      padding: 0;

      @include media-breakpoint-up(md) {
        padding: 0 $valio-container-padding-md $valio-container-padding-md $valio-container-padding-md;
      }

      @include media-breakpoint-up(lg) {
        padding: 0 $valio-container-padding $valio-container-padding $valio-container-padding;
      }
    }
  }
}

// Workaround for width bug
cx-page-layout {
  &.AccountPageTemplate {
    cx-page-slot {
      > valio-cx-sidebar {
        @include valio-side-panel-content();
      }
    }
  }
}
