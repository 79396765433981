valio-header-news {
  position: relative;
  display: block;
  padding: 0 24px 24px 24px;

  @include media-breakpoint-up(lg) {
    display: inline-block;
    padding: 8px 16px 8px 0;
  }
  @include media-breakpoint-up(xl) {
    padding: 8px 40px 8px 0;
  }

  a {
    @include valio-copy-small-16();

    @include media-breakpoint-up(lg) {
      padding: 8px;
    }
  }

  &:hover {
    .header-news-list {
      @include media-breakpoint-up(lg) {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .header-news-list {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 24px;
    -webkit-transition: opacity 0.4s;
    transition: opacity 0.4s;
    z-index: 200;
    width: 440px;

    .header-news-list-container {
      background: var(--cx-color-white);
      box-shadow: -8px 9px 25px rgba(0, 0, 0, 0.08);
      padding: 16px 32px;
      margin-top: 43px;

      valio-cx-news-item {
        .news-item {
          padding: 16px 0;

          .news-date,
          .news-partner {
            @include valio-tiny-12();
            color: var(--cx-color-gray);
            width: auto;
          }

          .news-date {
            &:after {
              margin: 0 5px;
              content: "|";
            }
          }

          .news-title {
            display: none;
          }

          .news-popup-title {
            display: block;

            a {
              @include valio-bold-link-mini-14();
              padding: 0;
            }
          }

          .news-url {
            display: none;
          }
        }

        &:last-child {
          .news-item {
            border-bottom: 1px solid var(--cx-color-iris);
          }
        }

        &:nth-child(n+4) {
          display: none;
        }
      }

      .header-news-read-more {
        padding: 16px 0;

        a {
          @include valio-bold-link-mini-14();
          padding: 0;
        }
      }
    }
  }
}


valio-cx-news-item {
  .news-item {
    @include valio-copy-mini-14();
    display: block;
    padding: 16px;
    border-bottom: 1px solid var(--cx-color-iris);

    .news-date,
    .news-partner {
      display: inline-block;
      width: 20%;
    }

    .news-title {
      display: inline-block;
      width: 50%;
    }

    .news-popup-title {
      display: none;
    }

    .news-url {
      @include valio-bold-link-mini-14();
      display: inline-block;
      width: 10%;
      text-align: right;
    }
  }

  &:last-child {
    .news-item {
      border-bottom: none;
    }
  }
}
