valio-cx-input,
.valio-input {
  display: block;
  margin-bottom: 16px;

  &.dark-input {
    .input-label {
      border: 1px solid var(--cx-color-dark);
    }
  }

  &.small-input {
    .input-label {
      height: $valio-input-height-sm;
      padding: 4px 12px;

      &.has-value,
      &:focus {
        input {
          margin-top: -4px;
        }
      }

      input {
        margin: -12px 0 0;
      }
    }
  }

  &.small-text {
    .input-label {
      input {
        @include valio-copy-mini-14();
      }
    }
  }

  &.disabled {
    pointer-events: none;

    .input-label {
      border: none;
      padding: 0 16px;

      &:not(.has-value) {
        display: none;
      }

      label {
        @include valio-copy-mini-14();
        visibility: visible;
      }

      &.has-value {
        border: none;

        input {
          margin-top: -8px;
        }
      }
    }
  }

  .input-label {
    @include valio-border-radius($valio-btn-border-radius);
    height: $valio-input-height-lg;
    border: 1px solid var(--cx-color-light);
    position: relative;
    padding: 8px 16px;

    ::-webkit-input-placeholder {
      color: var(--cx-color-gray);
    }

    :-moz-placeholder { /* Firefox 18- */
      color: var(--cx-color-gray);
    }

    ::-moz-placeholder { /* Firefox 19+ */
      color: var(--cx-color-gray);
    }

    :-ms-input-placeholder {
      color: var(--cx-color-gray);
    }

    &.has-value,
    &:focus {
      border: 1px solid var(--cx-color-dark);
      outline: none;

      label {
        visibility: visible;
      }

      input {
        margin-top: 0;
      }

      ::-webkit-input-placeholder {
        color: var(--cx-color-transparent);
      }

      :-moz-placeholder { /* Firefox 18- */
        color: var(--cx-color-transparent);
      }

      ::-moz-placeholder { /* Firefox 19+ */
        color: var(--cx-color-transparent);
      }

      :-ms-input-placeholder {
        color: var(--cx-color-transparent);
      }
    }

    &.is-invalid {
      border: 1px solid var(--cx-color-red);
    }

    label {
      @include valio-tiny-12();
      margin: 0;
      line-height: 100%;
      visibility: hidden;

      .label-content {
        white-space: nowrap;
        overflow: hidden;
      }
    }

    input {
      @include valio-copy-small-16();
      display: block;
      border: none;
      margin: -8px 0 0;
      padding: 0;
      outline: 0;
      height: 16px;
      background: none;

      /* Safari 7.1+ */
      ::-webkit-full-page-media, :future, :root {
        height: auto;
      }

      /* Safari 10.1+ (alternate method) */
      @media not all and (min-resolution: .001dpcm) {
        @supports (-webkit-appearance:none) {
          height: auto;
        }
      }
      /* Safari 9.0 (iOS Only) */
      @supports (-webkit-text-size-adjust:none) and (not (-ms-ime-align:auto)) and (not (-moz-appearance:none)) {
        height: auto;
      }

      &.form-control[type=text],
      &.form-control[type=email],
      &.form-control[type=password] {
        &:focus {
          background-color: var(--cx-color-transparent);
        }
      }
    }
  }

  .show-password,
  .extra-info {
    @include valio-tiny-12();
    margin-top: 8px;

    a {
      color: var(--cx-color-text);
      cursor: pointer;
    }
  }

  .invalid-feedback {
    color: var(--cx-color-danger);

    &::before {
      content: none;
    }
  }
}

.form-control {
  &::placeholder {
    color: var(--cx-color-gray);
  }

  &:focus {
    background: var(--cx-color-white);
    border: 1px solid var(--cx-color-dark);
  }
}

.form-group {
  input {
    &[type=text] {
      &::placeholder {
        color: var(--cx-color-gray);
      }
    }
  }
}

ng-select {
  &.ng-select {
    padding: 0;

    &.ng-select-opened {
      > .ng-select-container {
        border: 1px solid var(--cx-color-dark);
      }
    }

    &.ng-select-focused {
      &:not(.ng-select-opened) {
        > .ng-select-container {
          border-color: var(--cx-color-dark);
          box-shadow: none;
        }
      }
    }

    &,
    &.ng-select-single {
      .ng-select-container {
        padding: 8px 16px;
        height: $valio-input-height-lg;

        &:hover {
          box-shadow: none;
        }

        .ng-value-container {
          padding: 0;

          .ng-placeholder {
            color: var(--cx-color-gray);
          }

          .ng-input {
            padding: 8px 16px;
            height: $valio-input-content-lg;
          }
        }
      }
    }
  }
}
