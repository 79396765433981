valio-cx-order-confirmation-thank-you-message {
  background-color: var(--cx-color-light-purple);

  .cx-order-confirmation-message {
    padding: 80px 24px;

    @include media-breakpoint-up(md) {
      min-height: 376px;
      padding: 80px 0 80px 80px;
    }
    @include media-breakpoint-up(lg) {
      min-height: 496px;
      padding: 128px 0 112px 96px;
    }

    h1 {
      margin-bottom: 40px;

      @include media-breakpoint-down(md) {
        @include valio-H1-38();
        margin-bottom: 32px;
      }
    }

    p {
      @include valio-copy-18();
    }

    .cx-order-confirmation-actions {
      margin-top: 72px;
      margin-bottom: 40px;
    }
  }

  .cx-order-confirmation-image {
    height: 100%;

    cx-page-slot {
      height: 100%;

      cx-media {
        height: 100%;

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

valio-cx-order-confirmation-items {
  .cx-order-items {
    margin: auto;

    @include media-breakpoint-down(md) {
      width: auto;
    }

    valio-order-header {
      .header-info-slot {
        padding: 10px 32px 10px 24px;
        margin-bottom: 16px;

        .header-block {
          &.edit-order {
            @include valio-bold-link-mini-14();
            color: var(--cx-color-white);
            line-height: $valio-input-height-sm;
            text-align: right;

            a {
              color: var(--cx-color-white);
            }
          }
        }
      }
    }
  }

  .order-summary-block {
    @include valio-copy-18();
    @include valio-border-radius($valio-lg-border-radius);
    margin: 0;

    @include media-breakpoint-up(md) {
      margin: 16px 0 0;
    }

    @include media-breakpoint-up(lg) {
      margin: 80px 0 0;
    }

    .bold {
      @include valio-link-bold-18();
    }

    .price-summary {
      @include valio-border-radius($valio-lg-border-radius 0 0 $valio-lg-border-radius);
      background: var(--cx-color-white);
      padding: 56px;

      @include media-breakpoint-down(md) {
        padding: 56px 24px 0 24px;
      }

      .price-summary-header {
        margin-bottom: 40px;
      }

      .payment-info-section {
        margin-bottom: 48px;

        .cx-right {
          float: right;
        }
      }

      .price-summary-total {
        color: var(--cx-color-purple);
        padding-top: 40px;
        border-top: 2px solid;

        .cx-right {
          text-align: right;
        }
      }
    }

    .open-orders-summary {
      @include valio-border-radius($valio-lg-border-radius);
      background: var(--cx-color-light-peach);
      padding: 56px;

      @include media-breakpoint-down(md) {
        padding: 40px 24px;
      }

      .orders-info-section {
        text-align: center;

        @include media-breakpoint-down(md) {
          padding: 0;
          border: none;
        }

        &.left {
          @include media-breakpoint-up(lg) {
            border-right: 1px solid var(--cx-color-rosa);
          }
        }

        h3 {
          @include media-breakpoint-down(md) {
            @include valio-H3-24();
          }
        }

        p {
          @include valio-copy-18();
          padding: 16px 0 32px 0;
        }
      }
    }
  }

  .cx-order-items-header {
    margin: 64px 16px 32px;

    @include media-breakpoint-down(md) {
      @include valio-H3-24();
    }

    @include media-breakpoint-up(md) {
      margin: 72px 0 32px;
    }

    @include media-breakpoint-up(lg) {
      margin: 72px 0 40px;
    }
  }

  .cart-details-wrapper {
    background-color: var(--cx-color-transparent);

    .cart-details-section {
      background-color: var(--cx-color-white);
      margin-bottom: 16px;

      .cart-entry-totals {
        padding: 0 16px;
      }
    }

    .submit-row {
      @include valio-copy-small-16();
      color: var(--cx-color-purple);
      padding: 24px 16px 32px;

      @include media-breakpoint-up(md) {
        padding: 32px 32px 24px 40px;
      }
      @include media-breakpoint-up(lg) {
        padding: 32px 32px 32px 40px;
      }

      .submit-title {
        color: var(--cx-color-black);
      }

      @include valio-cart-header-products-totals-mixin();

      .totals {
        .cx-price {
          @include media-breakpoint-down(sm) {
            @include valio-link-bold-18();
          }
        }
      }
    }

    .cart-entry-block {
      padding: 0;

      .cx-item-list-row {
        .cx-item-list-items {
          &:last-child {
            .cx-item {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
