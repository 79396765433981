valio-cx-product-nutrition {
  @include valio-copy-18();

  .facets {
    margin: auto;
    width: 50%;
    margin-bottom: 5rem;
    text-align: center;

    span {
      @include valio-border-radius(25px);
      background-color: var(--cx-color-blueberry);
      padding: 0.8rem;
      margin: 1rem;
    }

    svg {
      margin-top: 7rem;
    }
  }
  .producer-list {
      list-style-type: none;
  }
  .nutrition-wrapper {
    border-top: 1px solid var(--cx-color-gray);

    @include media-breakpoint-up(md) {
      margin: 0 16px;
    }

    @include media-breakpoint-up(lg) {
      margin: 0;
    }

    .nutrition {
      padding-top: 64px;
      margin-bottom: 80px;

      @include media-breakpoint-down(md) {
        padding-top: 0;
        margin-bottom: 0;
        border-bottom: 1px solid var(--cx-color-gray);
      }

      &-left {
        border-right: 1px solid var(--cx-color-gray);
        padding-right: 128px;

        @include media-breakpoint-down(md) {
          border-right: none;
          padding-right: 0;
        }
      }

      &-right {
        padding-left: 128px;

        @include media-breakpoint-down(md) {
          padding-left: 0;
          margin-bottom: 3rem;
        }
      }

      &-title {
        @include valio-link-bold-18();
        margin-top: 24px;
        margin-bottom: 24px;

        @include media-breakpoint-down(sm) {
          @include valio-link-bold-small-16();
        }
      }

      &-info {
        @include media-breakpoint-down(md) {
          margin-bottom: 3rem;
        }

        @include media-breakpoint-down(sm) {
          @include valio-copy-small-16();
        }
      }

      &-header {
        @include media-breakpoint-down(md) {
          padding: 16px 0;
        }

        h2 {
          @include media-breakpoint-down(md) {
            display: inline;
          }

          @include media-breakpoint-down(sm) {
            @include valio-link-bold-18();
          }
        }

        .valio-icon {
          float: right;
          margin-left: auto;
          margin-right: 0;
          display: none;

          @include media-breakpoint-down(md) {
            display: block;
          }
        }
      }
    }

    .packaging-images {
      margin-top: 24px;
      margin-bottom: 24px;

      .packaging-image {
        display: inline-block;

        .image {
          margin: 0 8px;

          img {
            height: 64px;
          }
        }
      }
    }
  }

  .table-classification {
    width: 100%;

    td {
      &.table-features {
        text-align: right;
      }
    }
  }
}
