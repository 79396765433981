valio-cx-baseorder-content {
  valio-baseorder-search {
    .ng-select {
      margin-bottom: 16px;
    }

    valio-suggestive-cx-searchbox {
      label {
        margin-bottom: 16px;
      }
    }

    .week-day-column {
      @include media-breakpoint-up(sm) {
        max-width: 14.28%;
      }
    }

    .week-day-selector {
      &.form-check {
        margin: 0 0 16px 0;

        label {
          height: $valio-input-height-lg;

          input {
            border: none;
            margin: 0;
          }

          .checkmark {
            top: 0;
            bottom: 0;
            margin: auto;
          }

          .form-check-label {
            display: block;
            top: 0;
            line-height: $valio-input-height-lg;
          }
        }
      }
    }
  }

  .cart-details-wrapper {
    .cart-entry-block {
      valio-cx-cart-item {
        .po-number {
          width: auto;
        }

        .cart-item-status-actions {
          flex-wrap: wrap;
        }
      }
    }
  }

  .order-template {
    &-header {
      @include media-breakpoint-up(md) {
        p {
          padding: 0 3rem;
        }
      }
    }

    &-content {
      padding: 24px;
    }
  }
}
